export interface SitterInterviewContent {
  portrait: string;
  content: string;

  sitterName: string;
  sitterInfo: string; // 히어로 1기

  url: string;        // 더보기 링크
};
export interface SitterReviewContent {
  id: number;
  star: number;
  title: string;
  content: string;
  author: string;
  thumbnail: string;
  url: string;        // 더보기 링크
};
export interface GuideContents {
  title: string;
  subTitle: string;

  portrait: string;
};
export interface ActivityContents {
  title: string;
  image: string;
}

export const SitterInterviewContents = [
  {
    portrait: require('asset/home/sitter/1.jpg').default,
    content: `먼저 아이들과 눈높이를 맞추겠습니다. 
아이의 자율성을 존중해주고 
아이의 조력자로써 
안전한 울타리가 되겠습니다!`,
    sitterName: '김현정 선생님',
    sitterInfo: '히어로 19기',
    url: 'https://blog.naver.com/sitterhero/222501379906',
  },
  {
    portrait: require('asset/home/sitter/2.jpg').default,
    content: `아이에게 답을 주기보다는 질문하며 
아이가 스스로 방법을 찾아가며 
해결 할 수 있도록 돕겠습니다!`,
    sitterName: '장소란 선생님',
    sitterInfo: '히어로 9기',
    url: 'https://blog.naver.com/sitterhero/222501379906',
  },
  {
    portrait: require('asset/home/sitter/3.jpg').default,
    content: `10년동안 피아노학원 선생님으로 
오랜 시간 아이들을 만나면서 느낀 것은 
아이들이 행복해야한다는 것입니다. 
교육을 바탕으로 사랑으로 아이를
돌보겠습니다!`,
    sitterName: '박정숙 선생님',
    sitterInfo: '히어로 19기',
    url: 'https://blog.naver.com/sitterhero/222501379906',
  },
  {
    portrait: require('asset/home/sitter/4.jpg').default,
    content: `아이에게는 사랑과 정성을 다하는 
선생님, 부모님과는 소통하는 
육아메이트가 되겠습니다!`,
    sitterName: '이호인 선생님',
    sitterInfo: '히어로 6기',
    url: 'https://blog.naver.com/sitterhero/222501379906',
  },
  {
    portrait: require('asset/home/sitter/5.jpg').default,
    content: `젊은 날, 저도 워킹맘이었기에 
젊은 부모님들의 안타까움을 잘 압니다.
커가는 아이들에게 사랑을 주며 
직장에서 부모님들이 역량을 
발휘할 수 있도록 힘이 되겠습니다.`,
    sitterName: '고혜경 선생님',
    sitterInfo: '히어로 26기',
    url: 'https://blog.naver.com/sitterhero/222501379906',
  },
  {
    portrait: require('asset/home/sitter/6.jpg').default,
    content: `아이들이 저마다의 
자기다움으로 자라날 수 있도록, 
아이의 이야기를 귀 기울여 듣고 
마음을 이해하고 공감하겠습니다!`,
    sitterName: '신영숙 선생님',
    sitterInfo: '히어로 11기',
    url: 'https://blog.naver.com/sitterhero/222501379906',
  },
] as SitterInterviewContent[];

export const SitterReviewContents = [
  {
    star: 5,
    thumbnail: require('asset/home/review/1.jpg').default,
    title: '기존 베이비시터와는 달라요!',
    content: `하나하나 칭찬을 아끼지 않으시는 모습이 정말 보기 좋았어요. 돌발상황에도 싫은 티 한번 안내시고 원하는 놀이를 함께 해주셔서 감사했어요!!`,
    author: '강남욱님 (4살 레이, 3살 로이 엄마)',
    url: 'https://blog.naver.com/knw1124/221187655349',
  },
  {
    star: 5,
    thumbnail: require('asset/home/review/2.jpg').default,
    title: '엄마들의 히어로가 되어주시네요!',
    content: `3시간 동안 책읽기, 그림그리기, 오리기, 블록놀이, 쉴틈없이 꽉꽉 채워 놀아주시고 아이들과 함께 정리놀이까지 해주셨어요. 육아에 지친 엄마들에게 정말 히어로가 되어주시네요`,
    author: '구윤나님 (5살 이현이 엄마)',
    url: 'https://blog.naver.com/danceqn/221269102473',
  },
  {
    star: 5,
    thumbnail: require('asset/home/review/3.jpg').default,
    title: '아이 맞춤 놀이를 해주셨어요!',
    content: `서준이가 만지고하는 촉감놀이를 좋아하신다는 말을 기억하시고 뻥튀기를 가져오셨어요. 아이가 얼마나 좋아하던지, 아이가 관심 가지는 것에 맞춘 아이주도 놀이를 해주셔서 너무 좋았어요.`,
    author: '윤경희님 (2살 서준이 엄마)',
    url: 'https://blog.naver.com/ykhee23/221513367651',
  },
  {
    star: 5,
    thumbnail: require('asset/home/review/4.jpg').default,
    title: '최선을 다해주시네요!',
    content: `아들내미가 정말 즐거워했고 선생님도 활동적인 우리 아이 놀아주시느라 힘드셨을텐데 최선을 다해주시는 모습이 보기 좋았어요~`,
    author: '이혜진님 (6살 지용이 엄마)',
    url: 'https://blog.naver.com/jump841024/221229081142',
  },
  {
    star: 5,
    thumbnail: require('asset/home/review/5.jpg').default,
    title: '자녀양육 경험이 있으셔서 다르세요!',
    content: `자녀양육 경험이 있는 선생님이어서 그런가 차분하면서도 큰 딸의 요구를 잘 들어주셨어요. 아이 주도 기반으로 놀이를 해주시기 때문에 당연히 좋을 수 밖에 없어요. 이 날 만큼은 큰 아이를 위한 시간을 보내주셔서 정말 좋았어요^^`,
    author: '이미혜님(5살 민준이 엄마)',
    url: 'https://blog.naver.com/mhlee1771/221183412336',
  },
] as SitterReviewContent[];

export const GuideContents = [
  {
    title: '1. 돌봄 서비스 신청서 작성',
    subTitle: `아이의 성향 및 요청사항을 자세히
    작성해주시는 것이 좋아요!`,
    portrait: require('asset/home/guide/guide1.png').default,
  },
  {
    title: '2. 히어로 선생님 소개서 받기',
    subTitle: `신청해주신 조건에
    부합하는 선생님을 담당매니저가
    서칭해서 직접 찾아드려요`,
    portrait: require('asset/home/guide/guide2.png').default,
  },
  {
    title: '3. 히어로 선생님과의 면접',
    subTitle: `면접을 원하실 경우
    대면면접 또는 전화면접 중
    선택하실 수 있어요`,
    portrait: require('asset/home/guide/guide3.png').default,
  },
  {
    title: '4. 돌봄 진행',
    subTitle: `히어로 선생님과 아이가 안전한 
    등하원을 하고 놀이를 하며
    행복한 추억을 쌓아요`,
    portrait: require('asset/home/guide/guide4.png').default,
  },
  {
    title: '5. 히어로 성장노트',
    subTitle: `돌봄 후에는 히어로 선생님이 직접 작성한 
    성장노트를 보고 아이가 오늘은 기분, 건강은 어땠는지 
    무엇을 했는지 알 수 있어요`,
    portrait: require('asset/home/guide/guide5.png').default,
  },
  {
    title: '6. 돌봄료 결제',
    subTitle: `정기돌봄의 경우 
    4주 기준 후불제로 돌봄료를 결제해요 
    (비정기의 경우, 돌봄이 끝난 다음 날 안내드려요)`,
    portrait: require('asset/home/guide/guide6.png').default,
  },
] as GuideContents[];

export const ActivityContents = [
  {
    title: '월간 보수교육 진행',
    image: require('asset/mobile/home/activity2.png').default,
  },
  {
    title: '히어로 커뮤니티 지원',
    image: require('asset/mobile/home/activity1.png').default,
  },
  {
    title: '1000가지 히어로 놀이법 제공',
    image: require('asset/mobile/home/activity3.png').default,
  },
] as ActivityContents[];
