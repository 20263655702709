import React from 'react';
import styled from 'styled-components';

import { HorizontalLayout, Space, Push } from 'atom/layout';
import { Backdrop } from './Backdrop';

interface AppDownloadPopupProps {
  onClose: () => void;
};
export const AppDownloadPopup = ({
  onClose,
}: AppDownloadPopupProps) => {
  return (
    <Backdrop
      onClick={onClose}
    >
      <Container
        className="animate__animated animate__faster animate__fadeInUp"
        onClick={e => e.stopPropagation()}
      >
        <Space height={30} />
        <HorizontalLayout center>
          <Icon
          />
          <Space width={10} />
          <Title>
            첫 등록이라면 누구나<br/>
            돌봄 요금 10,000원 할인!
          </Title>
        </HorizontalLayout>
        <Space height={30} />

        <InfoBox>
          우리동네 돌봄히어로 앱 다운받기
        </InfoBox>
        <Space height={10} />

        <DismissText
          onClick={onClose}
        >
          모바일 웹으로 볼래요
        </DismissText>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0px;
  bottom: -2px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 250px;

  background: white;

  align-items: center;

  z-index: 101;
`;
const Icon = styled.img.attrs({
  src: require('asset/mobile/icon.png').default,
})`
  width: 82px;
  height: 82px;
`;
const Title = styled.div`
  font-size: 17px;
  font-weight: bold;
`;
const InfoBox = styled.div`
  display: flex;
  width: 248px;
  height: 61px;
  border-radius: 10px;
  background-color: #f5f5f5;

  font-size: 16px;
  font-weight: 500;

  align-items: center;
  justify-content: center;
`;
const DismissText = styled.div`
  color: #757575;

  font-size: 12px;
  font-weight: normal;
`;
