import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import Slider from 'react-slick';

import { HorizontalLayout, VerticalLayout, Push, Space } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { ImageBox } from 'atom/image';
import {
  Black24,
  Gray14,
  White16,
  White24,
  LightGray16,
  Gray18,
  Primary34,
  Gray16,
  Gray24,
} from 'atom/text';
import { Header } from 'component-mobile/header';
import { Footer } from 'component-mobile/footer';
import { FaqAccordianItem } from 'component/faq';
import {
  SitterActivityCard,
  SitterCount,
  SitterIntroCarousel,
} from 'component/sitter';
import { Color } from 'constant';
import { useFaq } from 'state/hook';
import { useFadeClasses } from 'util/index';

export const SitterIntroPage = ({}) => {
  const history = useHistory();
  const faq = useFaq('hero');
  useFadeClasses();

  return (
    <>
      <Header />
      <VerticalLayout center>
        <ImageBox
          src={require('asset/mobile/sitter/intro_background.jpg').default}
          imageStyle={{
            filter: 'brightness(0.63)',
            imageRendering: '-webkit-optimize-contrast',
          }}
          minHeight={440}
          maxHeight={440}
        >
          <VerticalLayout center style={{ height: '100%' }}>
            <Push />
            <TopTitle>
              당신의 육아 경험도
              <span style={{ color: '#f69F30' }}>&nbsp;경력</span>
              입니다.
            </TopTitle>
            <Space height={16} />
            <White16 center>
              육아 경험을 살려 히어로 <br />
              선생님으로 활동해 보세요!
            </White16>
            <Space height={26} />
            <RoundButton
              variant="primary"
              style={{ width: 'calc(100% - 80px)' }}
              onClick={() => history.push('/hero/apply')}
            >
              히어로 선생님 지원하기
            </RoundButton>
            <Space height={40} />
          </VerticalLayout>
        </ImageBox>

        <Space height={64} />
        <Gray24 bold center>
          “히어로를 통해 제 경력을
          <br />
          다시 찾았어요”
        </Gray24>
        <Space height={32} />
        <img
          src={require('asset/sitter/intro1.png').default}
          style={{
            width: 'calc(100% - 40px)',
            alignSelf: 'center',
            borderRadius: '24px',
          }}
        />
        <Space height={24} />
        <SmallQuote>
          “우리동네 돌봄히어로를 통해서
          <br />
          다시 제가 좋아하는 일을 할 수 있게 되어서 너무 좋아요. <br />
          <span style={{ color: '#f69F30', fontWeight: 700 }}>
            자라는 아이들을 돌보는 일이 보람있고 행복해요.
          </span>
          ”
        </SmallQuote>
        <Space height={12} />
        <Gray14 bold>허평회 히어로 선생님</Gray14>
        <Space height={64} />

        <VerticalLayout
          center
          style={{ width: '100%', background: 'rgba(246, 159, 48, 0.04)' }}
        >
          <Space height={64} />
          <SitterCount />
          <Space height={40} />
          <Gray24 bold center>
            의 선생님과 함께하고 있어요!
          </Gray24>
          <Space height={20} />
          <SitterIntroCarousel />
          <Space height={32} />
          <Gray16 center>
            현재 많은 선생님들이 돌봄선생님으로 활동 중에 있어요. <br />
            여러분의 육아 경험을 경력으로 활용하여
            <br />
            히어로 선생님이 되어보세요!
          </Gray16>
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout center style={{ width: '100%', background: 'white' }}>
          <Space height={64} />
          <Black24 bold center>
            히어로 선생님,
            <br />
            이런 분에게 추천해요!
          </Black24>
          <Space height={32} />
          <SVG
            src={require('asset/mobile/sitter/recommendations.svg').default}
            style={{ maxWidth: '75vw', overflow: 'visible' }}
          />
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: 'rgba(246, 159, 48, 0.04)' }}
        >
          <Space height={64} />
          <Black24 bold center>
            선생님은 어떤 일을 하나요?
          </Black24>
          <Space height={5} />
          <LightGray16>
            선생님이 원하는 업무만 선택해서 일하실 수 있어요!
          </LightGray16>
          <Space height={32} />
          <CarouselContainer>
            <Slider centerMode variableWidth adaptiveHeight dots>
              <img
                src={require('asset/mobile/sitter/card1.png').default}
                style={{
                  width: '295px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <img
                src={require('asset/mobile/sitter/card2.png').default}
                style={{
                  width: '295px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <img
                src={require('asset/mobile/sitter/card3.png').default}
                style={{
                  width: '295px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
            </Slider>
          </CarouselContainer>
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout center style={{ width: '100%', background: 'white' }}>
          <Space height={64} />
          <Black24 bold center>
            히어로 선생님으로
            <br />
            활동해야하는 이유{' '}
            <span style={{ color: Color.Primary }}>세가지</span>!
          </Black24>
          <Space height={12} />
          <LightGray16 center>
            히어로 센터가 선생님의 든든한 지원군이 되어드려요.
          </LightGray16>
          <Space height={32} />
          <SVG
            src={require('asset/mobile/sitter/packages.svg').default}
            style={{ maxWidth: '90vw', overflow: 'visible' }}
          />
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: 'rgba(246, 159, 48, 0.04)' }}
        >
          <Space height={64} />
          <Black24 bold center>
            지금 바로 히어로 선생님에
            <br />
            <span style={{ color: Color.Primary }}>지원</span>해 보세요!
          </Black24>
          <Space height={30} />
          <SVG
            src={require('asset/mobile/sitter/apply_steps.svg').default}
            style={{ maxWidth: '90vw', overflow: 'visible' }}
          />
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout center style={{ width: '100%', background: 'white' }}>
          <Space height={64} />
          <Black24 bold center>
            히어로만의 특별한 교육 커리큘럼
          </Black24>
          <Space height={32} />
          <SVG
            src={require('asset/mobile/sitter/classes.svg').default}
            style={{ maxWidth: '90vw', overflow: 'visible' }}
          />
          <Space height={64} />
        </VerticalLayout>

        <GrayBackground>
          <Space height={64} />
          <Gray24 semiBold center>
            Q & A
          </Gray24>
          <Space height={32} />
          <VerticalLayout center>
            <FaqContainer>
              {faq.map((x, idx) => (
                <FaqAccordianItem key={idx} data={x} />
              ))}
            </FaqContainer>
            <Space height={32} />
            <RoundButton
              style={{ width: '163px', padding: '0px 0px' }}
              onClick={() => history.push('/hero/apply')}
            >
              선생님 지원하기
            </RoundButton>
            <Space height={32} />
          </VerticalLayout>
        </GrayBackground>

        {/*}
        <BottomImage>
          <VerticalLayout center>
            <BottomTitle>
              히어로 선생님 지원혜택을<br />
              지인들에게 소개해주세요
            </BottomTitle>
            <Space height={35} />
            <CallCenter>
              고객센터 : 02-6232-0206
            </CallCenter>
            <Space height={35} />
            <RoundButton onClick={()=> {}}>
              친구에게 소개하기
            </RoundButton>
          </VerticalLayout>
        </BottomImage>
              */}
      </VerticalLayout>
      <Footer />
    </>
  );
};

const TopTitle = styled.div`
  color: white;

  font-size: 24px;
  font-weight: bold;
  text-align: center;

  line-height: 60px;
`;
const Quote = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  line-height: 22px;
  letter-spacing: -2px;
`;
const SmallQuote = styled.div`
  color: #484848;

  font-size: 14px;
  font-weight: 400;
  text-align: center;

  line-height: 22px;
  letter-spacing: -1.13px;
`;
const GrayBackground = styled.div`
  width: 100%;

  background: #f9f9f9;
`;
const FaqContainer = styled.div`
  width: calc(100% - 32px);

  align-self: center;
  background: #f9f9f9;
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 420px;

  overflow: hidden;

  .slick-dots {
    bottom: -36px;

    li {
      width: 12px;
      height: 12px;
    }
    li button:before {
      font-size: 12px;
    }
  }
`;
