import React from "react";
import styled from "styled-components";

import { HeroPlayContents } from "content";

interface HeroPlayCardProps {
  data: HeroPlayContents;
  onClick?: () => void;
};
export const HeroPlayCard = ({
  data,
  onClick,
}: HeroPlayCardProps) => {
  return (
    <Container>
      <InnerImage
        src={data.image}
      />
    </Container>
  );
};
const Container = styled.div`
  width: 355px;
  height: 255px;
  
  background: white;
  border-radius: 10px;
  margin: 6px;

  overflow: hidden;
`
const InnerImage = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;
