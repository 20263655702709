import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { UnderlinedText } from 'atom/text';
import { ChildPopup } from 'component/popup';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { ChildItem, NoChildrenData } from 'atom/child';
import { RequestStep10 } from './RequestStep10';

export const RequestStep9 = observer(({
  Bottom,
  onNext,
}) => {
  const { customerStore } = useStores();

  const onSelect = (value: string) => {
    if (value === 'x')
      customerStore.form.family = [];
  };

  useEffect(() => {
    if (!customerStore.form.family) return;

    if (customerStore.form.family.length === 0)
      customerStore.form.nok = '계시지 않아요';
    else
      customerStore.form.nok = customerStore.form.family.filter(x => x !== 'x').join(',');
  }, [customerStore.form.family]);

  return (
    <>
      <Title>
        선생님 방문 시,<br />
        누구와 함께 있나요?
      </Title>
      <Space height={8} />
      <Subtitle>
        *중복으로 선택이 가능합니다.
      </Subtitle>
      <Space height={60} />

      <MultiSelect
        twoColumns
        items={[
          { label: '아빠', value: '아빠' },
          { label: '엄마', value: '엄마' },
          { label: '할머니', value: '할머니' },
          { label: '할아버지', value: '할아버지' },
          { label: '도우미 이모님', value: '도우미 이모님' },
          { label: '계시지 않아요', value: 'x' },
        ]}
        value={customerStore.form.family.length === 0 ? ['x'] : customerStore.form.family.filter(x => x !== 'x')}
        onSelect={onSelect}
        onChange={e => customerStore.form.family = e}
      />
      <Space height={60} />
      <Bottom
        progress={1 / 11 * 9}
        onClickNext={() => onNext(RequestStep10)}
      />
    </>
  );
});
