import React from 'react';
import styled from 'styled-components';

import { HorizontalLayout, Space } from 'atom/layout';
import { Color } from 'constant';
import { Backdrop } from './Backdrop';

interface YesNoPopupProps {
  message: string;
  comment?: string;
  onYes?: () => void;
  onNo?: () => void;
  onClose: () => void;
};
export const YesNoPopup = ({
  message,
  comment,
  onYes,
  onNo,
  onClose,
}: YesNoPopupProps) => {
  return (
    <Backdrop
      onClick={onClose}
    >
      <Container
        onClick={e => e.stopPropagation()}
      >
        <TextContainer>
          <InnerText>
            {message}
          </InnerText>
          {!!comment && (
            <>
              <Space height={15} />
              <CommentText>
                {comment}
              </CommentText>
            </>
          )}
        </TextContainer>
        <HorizontalLayout>
          <BottomButton
            style={{
              backgroundColor: 'white',
              border: `1px solid ${Color.Primary}`,
              color: Color.Primary,
            }}
            onClick={() => { onNo?.(); onClose(); }}
          >
            아니오
          </BottomButton>
          <BottomButton
            onClick={() => { onYes?.(); onClose(); }}
          >
            네
          </BottomButton>
        </HorizontalLayout>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  width: 280px;

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  --animate-duration: 0.4s;
`;
const TextContainer = styled.div`
  padding: 30px 30px;
`;
const InnerText = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  line-height: 1.33;
  letter-spacing: -0.45px;
  white-space: break-spaces;
`;
const CommentText = styled.div`
  color: #707070;

  font-size: 13px;
  font-weight: 300;
  text-align: center;

  line-height: 1.46;
  letter-spacing: -0.33px
`;
const BottomButton = styled.div`
  display: flex;
  width: 50%;
  height: 52px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
