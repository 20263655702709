import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { Carousel } from 'react-responsive-carousel';

import { RoundButton } from 'atom/button';
import { HorizontalLayout, VerticalLayout, Space, Push } from 'atom/layout';
import { ImageBox } from 'atom/image';
import { Black19, Black20, Black23, Black28, Black40, Black48, White40, Gray9 } from 'atom/text';
import { Header } from 'component/header';
import { Footer } from 'component/footer';
import { ReviewCard, SitterCard } from 'component/home';
import { GuideSlide } from 'component/home/GuideSlide';
import { Banner } from 'component/banner';
import { SitterInterviewContents, SitterReviewContents, GuideContents } from 'content';
import { URL } from 'constant';
import { useHeroIntro, useReviews } from 'state/hook/value';

import { ReactComponent as Content1Svg } from 'asset/home/content1.svg';
import { ReactComponent as Content2Svg } from 'asset/home/content2.svg';
import { ReactComponent as Content3Svg } from 'asset/home/content3.svg';
import { ReactComponent as Content4Svg } from 'asset/home/content4.svg';
import { ReactComponent as GoogleBtn } from 'asset/home/btn_google_store.svg';
import { ReactComponent as AppleBtn } from 'asset/home/btn_apple_store.svg';

interface ArrowProp {
  type: string;
  onClick?: any;
}
const SitterInterviewArrow = ({type, onClick}: ArrowProp) => {
  const url = type === 'prev' ?
      require('asset/home/slide_arrow_prev_round.png').default
      : require('asset/home/slide_arrow_next_round.png').default;
  return (
      <SitterInterviewArrowContainer onClick={onClick} position={type}>
        <img style={{width: 87, height: 87}} src={url}/>
      </SitterInterviewArrowContainer>
  )
};
const GuideArrow = ({type, onClick}: ArrowProp) => {
  const url = type === 'prev' ?
      require('asset/home/slide_back.png').default
      : require('asset/home/slide_front.png').default;
  return (
      <GuideArrowContainer onClick={onClick} position={type}>
        <img style={{width: 19.2, height: 33}} src={url}/>
      </GuideArrowContainer>
  )
}

export const HomePage = ({

}) => {
  const history = useHistory();
  const reviews = useReviews();
  const intros = useHeroIntro();

  return (
    <>
      <Banner index={1} />
      <Header/>
      <Container center>
        <ImageBox
            src={require('asset/home/top.jpg').default}
            minHeight={500}
        >
          <HorizontalLayout style={{height: '100%'}}>
            <Push maxWidth={300}/>
            <VerticalLayout>
              <Push maxHeight={210}/>
              <Black48 bold>
                등하원/등하교<br/>
                전문 놀이돌봄 서비스
              </Black48>
              <Space height={13}/>
              <Black19>
                우리아이 믿고 맡기는, 우리동네 돌봄히어로
              </Black19>
              <Space height={44}/>

              <HorizontalLayout>
                <RoundButton
                    onClick={() => history.push('/service/apply')}
                >
                  돌봄서비스 신청
                </RoundButton>
                <Space width={50}/>
                <RoundButton
                    variant='white'
                    onClick={() => history.push('/hero/apply')}
                >
                  히어로 선생님 지원
                </RoundButton>
              </HorizontalLayout>
              <Space height={33}/>
              <Awards/>
              <Push maxHeight={210}/>
            </VerticalLayout>
            <Push />
            <Space width={170} />
            <Push maxWidth={300}/>
          </HorizontalLayout>
        </ImageBox>
        <Banner index={2} />
        <Space height={132}/>

        <Black40 bold center>
          우리동네 돌봄히어로를<br/>
          믿을 수 있는 이유, 세가지!
        </Black40>
        <Space height={95}/>
        <Content1Upper />
        <Space height={20} />
        <Content1 />
        <Space height={150} />

        <HeroContainer center>
          <Space height={143}/>
          <Black40 bold center>
            우리 히어로 선생님들은,<br/>
            이런 분들이랍니다!
          </Black40>
          <Space height={118}/>

          <Black28 semiBold center className='highlight'>
            까다로운 7단계 검증과정을 통한 선발!
          </Black28>
          <Space height={34}/>
          <Black20 semiBold center>
            서류심사와 면접심사에 합격하신 분들을 대상으로 교육을 진행하며 <br/>
            신원 검증이 모두 완료된 선생님들만 활동이 가능해요
          </Black20>
          <Space height={22}/>

          <Content2/>
          <Space height={268}/>

          <Black28 semiBold className='highlight'>
            보수교육과 피드백을 통한 꾸준한 성장 관리!
          </Black28>
          <Space height={34}/>
          <Black20 semiBold center>
            선발 후 관리가 더 중요하기에 매달 보수교육을 진행하고 <br/>
            부모님으로부터 받은 피드백을 바탕으로 선생님 역량을 개선하고 있어요!
          </Black20>
          <Space height={65}/>

          <Content3Upper />
          <Space height={20} />
          <Content3>
            <div>월간 보수교육 진행</div>
            <div>히어로 커뮤니티 지원</div>
            <div>1000가지 히어로 놀이법 제공</div>
          </Content3>
          <Space height={255}/>

          <Black28 semiBold center className='highlight'>
            아이들을 사랑하는 마음과
          </Black28>
          <Black28 semiBold center className='highlight'>
            숙련된 노하우로 준비된 선생님!
          </Black28>
          <Space height={34}/>
          <Black20 semiBold center>
            아이들의 신체적/정서적 안전을 지키는 것은 기본.<br/>
            1000가지 놀이법을 활용하여 아이들과 행복한 시간을 보내요!
          </Black20>
          <Space height={50}/>

          <SitterInterviewSlideWrap>
            <Slider
              centerMode
              variableWidth
              slidesToShow={1}
              autoplaySpeed={200}
              prevArrow={<SitterInterviewArrow type={'prev'}/> }
              nextArrow={<SitterInterviewArrow type={'next'} />}
            >
              {intros.map((x, idx) => (
                <SitterCard
                  key={idx}
                  data={x}
                />
              ))}
            </Slider>
          </SitterInterviewSlideWrap>
        </HeroContainer>
        <Space height={131}/>

        <Black40 bold center>
          히어로를 만난 부모님들의 실제 후기
        </Black40>
        <Space height={130}/>
        <ReviewContainer>
          <ReviewLabelWrap>
            <Label><Gray9>부모님 만족도</Gray9></Label>
            <HorizontalLayout center>
              <img
                style={{width: 26, height: 25, marginRight: 5}}
                src={require('asset/common/star.png').default}
              />
              <Black23 bold>
                9.8
              </Black23>
            </HorizontalLayout>
          </ReviewLabelWrap>
          <Space width={11}/>
          <div style={{width: 1, height: 55, backgroundColor: '#C0C0C0'}}/>
          <Space width={11}/>
          <ReviewLabelWrap>
            <Label><Gray9>정기 돌봄 기간</Gray9></Label>
            <Black23 bold>
              평균 8개월 이상
            </Black23>
          </ReviewLabelWrap>
        </ReviewContainer>
        <SitterReviewSlideWrap>
          <Slider
            centerMode
            variableWidth
            autoplay
            infinite
            autoplaySpeed={2000}
          >
            {reviews.map((x, idx) => (
              <ReviewCard
                key={idx}
                data={x}
              />
            ))}
          </Slider>
        </SitterReviewSlideWrap>
        <Space height={347}/>

        <VideoContainer>
          <Content4/>
          <VideoReview/>
        </VideoContainer>

        {/*}
        <GuideContainer>
          <GuideInner>
            <GuideTitle>
              <Black40 bold>우리동네 돌봄히어로,<br/>이렇게 이용하세요.</Black40>
              <Space height={290} />
              <HorizontalLayout>
                <BtnWrap 
                  onClick={() => showConfirm('COMING SOON!\n우리동네 돌봄히어로의 앱이\n곧 출시됩니다.')}
                  //onClick={() => window.open(URL.AndroidApp, '_blank')}
                >
                  <GoogleStore />
                </BtnWrap>
                <Space width={10} />
                <BtnWrap
                  onClick={() => showConfirm('COMING SOON!\n우리동네 돌봄히어로의 앱이\n곧 출시됩니다.')}
                  //onClick={() => window.open(URL.IosApp, '_blank')}
                >
                  <AppStore />
                </BtnWrap>
              </HorizontalLayout>
            </GuideTitle>
            <div style={{width: '100%', overflow: 'hidden'}}>
              <Slider
                fade
                dots
                infinite
                slidesToShow={1}
                prevArrow={<GuideArrow type={'prev'}/>}
                nextArrow={<GuideArrow type={'next'}/>}
              >
                {GuideContents.map((x, idx) => (
                  <GuideSlide
                    key={idx}
                    data={x}
                  />
                ))}
              </Slider>
            </div>
          </GuideInner>
        </GuideContainer>
                */}
        <Space height={48}/>

        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%', padding: '75px 0px', background: '#f9f9f9' }}>
          <Black40 bold center>
            많은 기관들과 함께 협력하고 있어요!
          </Black40>
          <Space height={145}/>
          {/*}
          <CompaniesContainer>
            <Slider
              dots
              slidesToShow={1}
            >
              <Companies />
            </Slider>
          </CompaniesContainer>
                  */}
          <Companies />
        </div>

        <ImageBox
          src={require('asset/home/bottom.jpg').default}
          minHeight={200}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
          <White40 bold>
            지금 바로 서비스 신청하기
          </White40>
          <Space height={35}/>
          <RoundButton
            onClick={() => history.push('/service/apply')}
          >
            돌봄서비스 신청
          </RoundButton>
        </ImageBox>
        <Banner index={3} />

        <GoToTopFAB />
        <FAB />
      </Container>
      <Footer/>
    </>
  );
};

const Container = styled(VerticalLayout)`
`;
const Awards = styled.img.attrs({
  src: require('asset/home/awards.png').default,
})`
  width: 455px;
`;
const Content1Upper = styled.img.attrs({
  src: require('asset/home/content1_upper.jpg').default,
})`
  width: calc(100% - 300px);
  max-width: 1080px;
`;
const Content1 = styled(Content1Svg)`
  width: calc(100% - 300px);
  max-width: 1080px;
`;
const Content2 = styled(Content2Svg)`
  width: calc(100% - 100px);
  max-width: 1000px;
`;
const Content3Upper = styled.img.attrs({
  src: require('asset/home/content3_upper.jpg').default,
})`
  width: calc(100% - 300px);
  max-width: 1000px;
`;
const Content3 = styled.div`
  width: calc(100% - 300px);
  max-width: 1000px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  font-size: 20px;
  font-weight: 500;
  text-align: center;
`;
const Content4 = styled(Content4Svg)`
  width: calc(50%);
  max-width: 500px;
`;
const VideoReview = styled.iframe.attrs({
  src: 'https://www.youtube.com/embed/MGS7_akEZ1o',
  frameBorder: 0,
})`
  width: calc(50%);
  max-width: 776px;
  height: 435px;

  border-radius: 15px;
  overflow: hidden;
`;
const HeroContainer = styled(VerticalLayout)`
  width: 100%;
  padding-bottom: 155px;
  background-color: #f9f9f9;
`;
const ReviewContainer = styled(VerticalLayout)`
  width: calc(100% - 100px);
  max-width: 1100px;

  display: flex;
  flex-direction: row;
`;
const VideoContainer = styled(HorizontalLayout)`
  width: 100%;
  padding-bottom: 208px;

  flex-wrap: wrap;
  gap: 40px;

  align-items: center;
  justify-content: center;
`;
const GuideContainer = styled.div`
  width: 100%;
  padding: 75px 0;

  background-color: #f9f9f9;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;
const GuideInner = styled.div`
  max-width: 1000px;
  position: relative;
`
const GuideTitle = styled.div`
  position: absolute;
  top: 108px;
  left: 0;
`
const GoToTopFAB = styled.img.attrs({
  src: require('asset/mobile/home/top.svg').default,
  onClick: () => window.scrollTo(0, 0)
})`
  position: fixed;
  right: 40px;
  bottom: 90px;

  width: 80px;

  cursor: pointer;
`;
const FAB = styled.img.attrs({
  src: require('asset/mobile/home/kakao.png').default,
  onClick: () => window.open(URL.Kakao, '_blank')
})`
  position: fixed;
  right: 40px;
  bottom: 20px;

  width: 80px;

  cursor: pointer;
`;
const CompaniesContainer = styled.div`
  max-width: 1000px;
  position: relative;

  overflow-x: hidden;

  padding-bottom: 40px;
`;
const Companies = styled.img.attrs({
  src: require('asset/home/companies.jpg').default,
})`
  width: calc(100% - 300px);
  max-width: 1000px;
  height: auto;

  align-self: center;
`;
const ReviewLabelWrap = styled.div`
  height: 55px;

  border-color: #c0c0c0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Label = styled.div`
  width: 80px;
  height: 23px;
  border-radius: 23px;

  background-color: #f7f8fa;

  display: flex;
  align-items: center;
  justify-content: center;
`
const SitterInterviewSlideWrap = styled.div`
  width: 100%;
  overflow: hidden;
  
  .slick-slide {
    opacity: 0.5;
  }
  .slick-active {
    opacity: 1;
  }
`
const SitterInterviewArrowContainer = styled.div<any>`
  position: absolute;
  top: 50%;

  cursor: pointer;
  transform: translateY(-50%);
  z-index: 10;

  ${({ position }) => position === 'prev'
    ? `left: calc(50% - 370px)`
    : `right: calc(50% - 370px)`
  };
`
const GuideArrowContainer = styled.div<any>`
  position: absolute;
  top: 268px;

  cursor: pointer;
  transform: translateY(-50%);
  z-index: 10;

  ${({ position }) => position === 'prev' ?
    `left: 465px` : ` right: 80px`};
`
const SitterReviewSlideWrap = styled.div`
  width: 100%;
  overflow: hidden;
  
  .slick-active {
    opacity: 1;
    
    & .review-card {
        border: solid 1px #f69f30;
        box-sizing: content-box;
    }
  }
`
const BtnWrap = styled.div`
  cursor: pointer;

  z-index: 1;
`
const GoogleStore = styled(GoogleBtn)`
`;
const AppStore = styled(AppleBtn)`
`;

