export const URL = {
  Kakao: 'http://pf.kakao.com/_eHtaxl/chat',
  Blog: 'https://blog.naver.com/sitterhero',
  Instagram: 'https://www.instagram.com/sitterhero',
  Band: 'https://band.us/band/70351181',

  Call: 'tel:0262320206',

  AndroidApp: '',
  IosApp: '',
};
