import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { FavoritePlays } from 'constant';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestPersonalityCheck3 } from './RequestPersonalityCheck3';

export const RequestPersonalityCheck2 = observer(({
  Bottom,
  onNext,
}) => {
  const { customerStore } = useStores();
  const [value, setValue] = useState<number[]>([]);

  useEffect(() => {
    if (!customerStore.childForm.favoriteGame) return;

    setValue(customerStore.childForm.favoriteGame.split('\n')
      .map(x => FavoritePlays.find(y => y.label === x)?.value),
    );
  }, []);
  useEffect(() => {
    customerStore.childForm.favoriteGame = 
      value.map(x => FavoritePlays.find(y => y.value === x).label).join('\n');
  }, [value]);

  return (
    <>
      <Title>
        우리 아이가 좋아하는<br/>
        놀이는 무엇인가요?
      </Title>
      <Space height={8} />
      <Subtitle>
        *중복으로 선택이 가능합니다.
      </Subtitle>
      <Space height={41} />

      <MultiSelect
        twoColumns
        items={FavoritePlays}
        value={value}
        onChange={setValue}
      />
      <Space height={60} />
      <Bottom
        disabled={value.length === 0}
        progress={1 / 5 * 3}
        onClickNext={() => onNext(RequestPersonalityCheck3)}
      />
    </>
  );
});
