import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import { DateTime } from 'luxon';

import { DatePicker, DateRangeInput, Labeled } from 'atom/input';
import { Space } from 'atom/layout';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep5Regular } from './RequestStep5Regular';
import { RequestStep5Irregular } from './RequestStep5Irregular';

export const RequestStep4 = observer(({
  Bottom,
  onNext,
}) => {
  const { customerStore } = useStores();
  const isValid = customerStore.form.careStarted
    && DateTime.fromFormat(customerStore.form.careStarted, 'yyyy-MM-dd').diffNow('days').days <= 62
    && DateTime.fromFormat(customerStore.form.careStarted, 'yyyy-MM-dd').diffNow('days').days >= 0;

  const onClickNext = () => {
    if (customerStore.form.careKind === '정기')
      onNext(RequestStep5Regular);
    else
      onNext(RequestStep5Irregular);
  };

  return (
    <>
      <Title>
        돌봄 시작일과<br />
        종료일을 선택해주세요.
      </Title>
      <Space height={8} />
      <Subtitle>
        *시작일은 최대 2개월 이내부터 선택 가능하며<br/>
        &nbsp;&nbsp;최소 일주일 전에 신청해주시면 가장 좋아요!
      </Subtitle>
      <Space height={60} />

      <Labeled
        label="시작일"
      >
        <DateRangeInput
          hasIcon
          isStart
          startDate={customerStore.form.careStarted ? DateTime.fromFormat(customerStore.form.careStarted, 'yyyy-MM-dd') : null}
          endDate={customerStore.form.careEnded ? DateTime.fromFormat(customerStore.form.careEnded, 'yyyy-MM-dd') : null}
          onChange={(start, end) => {
            customerStore.form.careStarted = start?.toFormat('yyyy-MM-dd');
            customerStore.form.careEnded = end?.toFormat('yyyy-MM-dd');
          }}
        />
      </Labeled>
      <Space height={28} />

      <Labeled
        label="종료일 (미정인 경우 선택하지 않으셔도 됩니다)"
      >
        <DateRangeInput
          hasIcon
          isStart={false}
          startDate={customerStore.form.careStarted ? DateTime.fromFormat(customerStore.form.careStarted, 'yyyy-MM-dd') : null}
          endDate={customerStore.form.careEnded ? DateTime.fromFormat(customerStore.form.careEnded, 'yyyy-MM-dd') : null}
          onChange={(start, end) => {
            customerStore.form.careStarted = start?.toFormat('yyyy-MM-dd');
            customerStore.form.careEnded = end?.toFormat('yyyy-MM-dd');
          }}
        />
      </Labeled>
      <Space height={60} />
      <Bottom
        progress={1 / 11 * 4}
        disabled={!isValid || !customerStore.form.careStarted}
        onClickNext={onClickNext}
      />
    </>
  );
});
