import React from 'react';
import styled from 'styled-components';

import { Space } from 'atom/layout';
import { useReviews } from 'state/hook/value';

export const CareReviews = () => {
  const reviews = useReviews();

  return (
    <Container>
      {reviews.map(x => (
        <>
          <MessageBox
            className="will-animate fadeInUp"
            dangerouslySetInnerHTML={{ __html: x.content }}
          />
          <Space height={48} />
          <img
            className="will-animate fadeInUp"
            style={{ alignSelf: 'center' }}
            src={{
              0: require('asset/customer/thumbnail/0.svg').default,
              1: require('asset/customer/thumbnail/1.svg').default,
              2: require('asset/customer/thumbnail/2.svg').default,
            }[x.thumbnail]}
          />
          <Space height={40} />
        </>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: calc(100% - 40px);
`;
const MessageBox = styled.div`
  width: 100%;

  background: #FFFFFF;
  box-shadow: 3px 4px 16px #DEDEDE;
  border-radius: 24px;

  padding: 16px 24px;

  p {
    margin: 0px 0px;
  }
`;
