import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

import { Checkbox, Labeled } from 'atom/input';
import { Black14 } from 'atom/text';
import { Space, HorizontalLayout } from 'atom/layout';
import { Color } from 'constant';
import { IChild } from 'model';
import { Backdrop } from './Backdrop';

interface ChildPopupProps {
  value: IChild;
  onSubmit: (x: IChild) => void;
  onClose: () => void;
};
export const ChildPopup = ({
  value,
  onSubmit,
  onClose,
}: ChildPopupProps) => {
  const [child, setChild] = useState<IChild>(value || {
    name: '',
    birthday: '',
    gender: 'F',
  } as IChild);

  const onComplete = () => {
    if (child.name.length <= 2 || child.name.length > 16) {
      alert('이름을 제대로 입력해 주세요.');
      return;
    }
    if (child.birthday.length !== 8 && isNaN(+child.birthday)) {
      alert('생일은 8자리 숫자로 입력해 주세요.')
      return;
    }

    onSubmit(child);
    onClose();
  };

  return (
    <Backdrop
      onClose={onClose}
    >
      <Container
        onClick={e => e.stopPropagation()}
      >
        <FormContainer>
          <Labeled
            label="이름"
          >
            <TextField
              variant="outlined"
              placeholder="입력란"
              style={{ width: '100%' }}
              error={child.name.length <= 2 || child.name.length > 16}
              value={child.name}
              onChange={e => setChild({ ...child, name: e.target.value })}
            />
          </Labeled>
          <Space height={20} />

          <Labeled
            label="생년월일 8자리"
          >
            <TextField
              variant="outlined"
              placeholder="YYYYMMDD"
              inputProps={{ maxLength: 8 }}
              style={{ width: '100%' }}
              error={child.birthday.length !== 8}
              value={child.birthday}
              onChange={e => setChild({ ...child, birthday: e.target.value })}
            />
          </Labeled>
          <Space height={20} />

          <Labeled
            label="이름"
          >
            <HorizontalLayout>
              <Checkbox
                value={child.gender === 'F'}
                onChange={e => setChild({ ...child, gender: 'F' })}
              />
              <Space width={8} />
              <Black14>
                여자아이
              </Black14>
              <Space width={40} />
              <Checkbox
                value={child.gender === 'M'}
                onChange={e => setChild({ ...child, gender: 'M' })}
              />
              <Space width={8} />
              <Black14>
                남자아이
              </Black14>
            </HorizontalLayout>
          </Labeled>
        </FormContainer>

        <BottomButton
          onClick={onComplete}
        >
          확인
        </BottomButton>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  width: 320px;

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  --animate-duration: 0.4s;
`;
const FormContainer = styled.form`
  padding: 24px 24px;
`;
const BottomButton = styled.div`
  display: flex;
  width: 100%;
  height: 52px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
