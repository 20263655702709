import React, { useState, useEffect } from 'react';

import { useStores } from 'state';

export const useFaq = (type: string) => {
  const { valueStore } = useStores();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    (async () => {
      setData(await valueStore.getFaq(type));
    })();
  }, []);

  return data;
};
