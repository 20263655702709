import styled from 'styled-components';

export const Title = styled.div`
  color: #484848;

  font-size: 24px;
  font-weight: bold;

  line-height: 1.54;
  letter-spacing: -1.3px;
`;
export const Subtitle = styled.div`
  color: #989898;

  font-size: 15px;
  font-weight: 500;

  letter-spacing: -0.8px;
`;
