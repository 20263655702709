import styled from 'styled-components';

export const Tag = styled.div`
  display: flex;
  height: 21px;

  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.28px;
  color: #969696;

  border-radius: 11px;
  border: solid 1px #969696;

  align-items: center;
  justify-content: center;

  padding: 0px 10px;
`;
