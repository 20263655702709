// https://github.com/SeeSoRuFree/woorihero-webapp-front/blob/master/src/components/new_care/store/newCare.slice.ts

export enum CareKind {
  Regular = '정기',
  Once = '비정기',
}

export enum CareType {
  DropOff = '등원 + 놀이돌봄',
  PickUp = '하원 + 놀이돌봄',
  PlayOnly = '놀이돌봄만',
  Premium = '창의 놀이',
  Cleaning = '청소 서비스',
  Cooking = '요리 서비스',
}
export const isAddonCareType = (x: CareType) => {
  return (
    x === CareType.Premium || x === CareType.Cleaning || x === CareType.Cooking
  );
};

export enum PickupMethod {
  Walk = '도보 이동',
  Bus = '대중 교통',
  Taxi = '택시',
  Car = '선생님 차량',
  SchoolBus = '스쿨 버스',
  None = '픽업 불필요',
}
