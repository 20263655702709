import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { Color } from 'constant';
import { useIsMobile } from 'util/index';

export const RoundButtonVariant = {
  Primary: 'primary',
  Secondary: 'secondary',
  White: 'white',
  Dark: 'dark',
} as const;

interface RoundButtonProps {
  variant?: ValuesOf<typeof RoundButtonVariant>;
  fill?: boolean;
  disabled?: boolean,
  style?: CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
};
export const RoundButton = ({
  variant = RoundButtonVariant.Primary,
  fill = false,
  disabled = false,
  children,
  onClick,
  ...props
}: RoundButtonProps) => {
  const isMobile = useIsMobile();

  return (
    <Container
      variant={variant}
      fill={fill}
      disabled={disabled}
      isMobile={isMobile}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<Partial<RoundButtonProps> | any>`
  display: flex;
  width: max-content;
  height: 62px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;

  border-radius: 75px;

  align-items: center;
  justify-content: center;

  padding: 16px 68px;

  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    filter: contrast(1.15);
  }
  &:active {
    filter: contrast(0.8);
  }

  ${({ fill }) => fill ? `
    flex: 1;
  ` : `
  `}
  ${({ disabled }) => disabled ? `
    filter: grayscale(1);
    pointer-events: none;
  ` : `
  `}
  ${({ variant }) => {
    return {
      [RoundButtonVariant.Primary]: `
        background-color: ${Color.Primary};
        color: white;
      `,
      [RoundButtonVariant.Secondary]: `
        background-color: ${Color.Secondary};
        color: ${Color.Primary};
      `,
      [RoundButtonVariant.White]: `
        background-color: white;
        border: 1px solid ${Color.Primary};
        color: ${Color.Primary};
      `,
      [RoundButtonVariant.Dark]: `
        background-color: #484848;
        color: white;
      `,
    }[variant!];
  }}
  ${({ isMobile }) => isMobile ? `
    height: 45px;

    font-size: 15px;

    padding: 12px 50px;
  ` : `
  `}
`;
