import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';

import { RoundButton } from 'atom/button';
import { Labeled, Select } from 'atom/input';
import { HorizontalLayout, Push, Space } from 'atom/layout';
import { useStores } from 'state';
import { VerificationStep } from 'model';
import { Title, Subtitle } from './style';
import { RegisterStep2 } from './RegisterStep2';
import { RequestStep1 } from '../request';

export const RegisterStep1 = observer(({
  Bottom,
  onNext,
  onLogin,
}) => {
  const { customerStore } = useStores();
  const [step, setStep] = useState(VerificationStep.InputNumber);

  const onSendCode = async () => {
    try {
      await customerStore.sendVerificationCode(
        customerStore.signUpForm.phone,
      );
      setStep(VerificationStep.Sent);
    } catch(e) {
      alert(e.message || '인증번호 전송이 실패했습니다.');
    }
  };
  const onVerifyCode = async () => {
    try {
      await customerStore.signIn(
        customerStore.signUpForm.phone,
        customerStore.signUpForm.accessNumber,
      );
      setStep(VerificationStep.Sent);
      onLogin();
    } catch(e) {
      if (e.status === 0) {
        await customerStore.signUp({
          ...customerStore.signUpForm,
          isAcceptTerms: 'Y',
          isMarketing: 'Y',
          isPrivacyPolicy: 'Y',
          email: `${Math.random()}${Date.now()}@temp.com`,
        });
        await customerStore.refreshMe();
        onNext(RequestStep1);
      } else {
        alert(e.message || '인증번호 검증이 실패했습니다.');
      }
    }
  };

  useEffect(() => {
    setStep(VerificationStep.InputNumber);
  }, [customerStore.signUpForm.phone]);

  return (
    <>
      <Title>
        언제나 가까이,<br />
        우리동네 돌봄히어로
      </Title>
      <Space height={18} />
      <Subtitle>
        현재 서울시에서만 가능한 서비스입니다.
      </Subtitle>
      <Space height={32} />

      <Labeled
        label="휴대폰 번호"
      >
        <HorizontalLayout>
          <TextField
            style={{ flex: 1 }}
            value={customerStore.signUpForm.phone}
            onChange={e => customerStore.signUpForm.phone = e.target.value}
          />
          <Space width={32} />
          <RoundButton
            variant="secondary"
            disabled={step !== VerificationStep.InputNumber}
            style={{ width: '112px', height: '42px', padding: 0, fontSize: '15px' }}
            onClick={onSendCode}
          >
            인증번호 전송
          </RoundButton>
        </HorizontalLayout>
      </Labeled>
      <Space height={40} />

      {step === VerificationStep.InputNumber && (
        <Space height={77} />
      )}
      {step !== VerificationStep.InputNumber && (
        <Labeled
          label="인증번호를 입력해주세요"
        >
          <HorizontalLayout>
            <TextField
              style={{ flex: 1 }}
              value={customerStore.signUpForm.accessNumber}
              onChange={e => customerStore.signUpForm.accessNumber = e.target.value}
            />
            <Space width={32} />
            <RoundButton
              variant="secondary"
              style={{ width: '112px', height: '42px', padding: 0, fontSize: '15px' }}
              onClick={onVerifyCode}
            >
              인증하기
            </RoundButton>
          </HorizontalLayout>
        </Labeled>
      )}
      <Space height={50} />
    </>
  );
});
