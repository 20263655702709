import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Input, TextField } from '@material-ui/core';
import { DateTime } from 'luxon';

import { RoundButton } from 'atom/button';
import { Space, Push, HorizontalLayout } from 'atom/layout';
import { Terms } from 'atom/terms';
import { StepIndicator } from 'atom/progress';
import { Checkbox, Labeled } from 'atom/input';
import { Black15, Black16, Gray16, LightGray16 } from 'atom/text';
import { AddressItem } from 'atom/address';
import { AddressPopup } from 'component/popup';
import { SitterRegisterTerms } from 'content';
import { VerificationStep } from 'model';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { ApplyDone } from './ApplyDone';

const getAge = (birthday: string) => {
  let date = DateTime.fromFormat(birthday, 'yyMMdd');
  if (date.year >= 2025)
    date = date.minus({ years: 100 });
  return -date.diffNow('years').years;
};

export const ApplyStep3 = observer(({
  Bottom,
  onNext,
}) => {
  const topmost = useTopmost();
  const { sitterStore } = useStores();
  const [birthday, setBirthday] = useState('');
  const [verificationStep, setVerificationStep] = useState(VerificationStep.InputNumber);
  const [code, setCode] = useState('');

  const onSendCode = async () => {
    console.log(getAge(birthday));
    if (getAge(birthday) > 64) {
      showConfirm('죄송합니다.\n만 64세까지만\n지원이 가능합니다.');
      return;
    }
    if (sitterStore.form.name.length < 2) {
      alert('성함을 입력해주세요.');
      return;
    }
    if (birthday.length !== 6) {
      alert('생일을 입력해주세요.');
      return;
    }
    if (sitterStore.form.phone.length < 10) {
      alert('휴대폰 번호를 입력해주세요.');
      return;
    }
    await sitterStore.sendVerificationCode(
      sitterStore.form.phone,
    );
    setVerificationStep(VerificationStep.Sent);
  };
  const onVerifyCode = async () => {
    try {
      await sitterStore.confirmVerificationCode(
        sitterStore.form.phone,
        code,
      );
      setVerificationStep(VerificationStep.Verified);
    } catch(e) {
      alert('인증에 실패하였습니다.');
    }
  };
  const onAddAddress = () => {
    const id = topmost.show((
      <AddressPopup
        value=""
        restrictToSeoul={false}
        onSubmit={(x, y) => sitterStore.form.address = x + ' ' + y}
        onClose={() => topmost.dismiss(id)}
      />
    ));
  };
  const onSubmit = async () => {
    try {
      await sitterStore.submitRegister(sitterStore.form);
      onNext(ApplyDone);
    } catch(e) {
      alert(e.message);
    }
  };

  useEffect(() => {
    if (verificationStep === VerificationStep.Sent)
      setVerificationStep(VerificationStep.InputNumber);
  }, [sitterStore.form.phone]);
  useEffect(() => {
    if (birthday.length === 6) {
      let date = DateTime.fromFormat(birthday, 'yyMMdd');
      if (!date.isValid) {
        alert('잘못된 생일입니다.');
        return;
      }
      if (date.year >= 2025)
        date = date.minus({ years: 100 });
      sitterStore.form.birthday = date.toISO();
    }
  }, [birthday]);

  return (
    <>
      <div style={{ position: 'absolute', top: '40px', right: '36px' }}>
        <StepIndicator
          value={1}
          maxStep={2}
        />
      </div>
      <Title>
        필수정보를 기입해주세요.
      </Title>
      <Space height={43} />

      <Labeled
        label="성함"
      >
        <TextField
          disabled={verificationStep !== VerificationStep.InputNumber}
          placeholder="성함을 입력해주세요"
          variant="outlined"
          style={{ width: '100%' }}
          value={sitterStore.form.name}
          onChange={e => sitterStore.form.name = e.target.value}
        />
      </Labeled>
      <Space height={20} />

      <Labeled
        label="생년월일 6자리"
      >
        <TextField
          disabled={verificationStep !== VerificationStep.InputNumber}
          placeholder="(예시)641021"
          variant="outlined"
          inputProps={{ maxLength: 6 }}
          style={{ width: '100%' }}
          value={birthday}
          onChange={e => setBirthday(e.target.value)}
        />
      </Labeled>
      <Space height={20} />

      <Labeled
        label="휴대폰 번호"
      >
        <TextField
          disabled={verificationStep === VerificationStep.Verified}
          placeholder="(예시) 01012345678"
          variant="outlined"
          inputProps={{ maxLength: 11, readOnly: verificationStep === VerificationStep.Verified }}
          style={{ width: '100%' }}
          value={sitterStore.form.phone}
          onChange={e => sitterStore.form.phone = e.target.value}
        />
        <Space height={10} />
        <HorizontalLayout center>
          {verificationStep === VerificationStep.Sent && (
            <TextField
              placeholder="인증번호 입력"
              variant="outlined"
              inputProps={{ maxLength: 6 }}
              value={code}
              onChange={e => setCode(e.target.value)}
            />
          )}
          {verificationStep === VerificationStep.Verified && (
            <LightGray16>
              인증되었습니다.
            </LightGray16>
          )}
          <Push />
          {verificationStep === VerificationStep.InputNumber && (
            <RoundButton
              variant="secondary"
              style={{ width: '158px', height: '46px', fontSize: '16px', padding: 0 }}
              disabled={sitterStore.form.phone.length === 0}
              onClick={onSendCode}
            >
              인증번호 전송
            </RoundButton>
          )}
          {verificationStep === VerificationStep.Sent && (
            <RoundButton
              variant="secondary"
              style={{ width: '158px', height: '46px', fontSize: '16px', padding: 0 }}
              disabled={code.length === 0}
              onClick={onVerifyCode}
            >
              인증하기
            </RoundButton>
          )}
        </HorizontalLayout>
      </Labeled>
      <Space height={60} />
      <hr />
      <Space height={40} />
        
      <Black16 bold>
        주소기입
      </Black16>
      <Space height={10} />
      <Gray16>
        현재 거주중인 ‘구’ 와 ‘동’을 적어주세요.
      </Gray16>
      <Space height={16} />
      <TextField
        placeholder="(예시) 관악구 조원동"
        variant="outlined"
        style={{ width: '100%' }}
        value={sitterStore.form.address}
        onChange={x => sitterStore.form.address = x.target.value}
      />
      <Space height={50} />
      <hr />
      <Space height={40} />

      <Labeled
        label="자녀 양육 경험"
      >
        <HorizontalLayout center>
          <Checkbox
            value={sitterStore.form.hasExperience}
            onChange={(e) => {
              if (e) sitterStore.form.hasExperience = true;
            }}
          />
          <Space width={10} />
          <Black16 bold>유</Black16>
          <Space width={100} />

          <Checkbox
            value={!sitterStore.form.hasExperience}
            onChange={(e) => {
              if (e) {
                showConfirm('자녀 양육경험이 없으실 경우,\n필수로 돌봄경험이 있으셔야 합니다.');
                sitterStore.form.hasExperience = false;
              }
            }}
          />
          <Space width={10} />
          <Black16 bold>무</Black16>
        </HorizontalLayout>
      </Labeled>
      <Space height={44} />
      <Labeled
        label="자녀 외 돌봄경험"
      >
        <textarea
          placeholder={`자녀 외에 돌봄경험이 있으시다면 자유롭게 작성해주세요. 양육경험이 없으실 경우, 필수로 돌봄경험이 있으셔야 합니다. (예 : 어린이집 보조교사 경험 등)`}
          value={sitterStore.form.experienceComment}
          onChange={e => sitterStore.form.experienceComment = e.target.value}
        />
      </Labeled>

      <Space height={50} />
      <hr />
      <Space height={40} />

      <Title>
        우리동네 돌봄히어로<br />
        서비스 이용약관 동의가 필요해요.
      </Title>
      <Space height={57} />

      <Terms
        data={SitterRegisterTerms}
        value={sitterStore.form.terms}
        onChange={e => sitterStore.form.terms = e}
      />

      <Space height={54} />
      <Bottom
        disabled={verificationStep !== VerificationStep.Verified
          || (sitterStore.form.terms[0] === false)
          || (sitterStore.form.terms[1] === false)
          || sitterStore.form.address.length === 0}
        label="완료"
        onClickNext={onSubmit}
      />
    </>
  );
});
