import { observable } from 'mobx';
import { ITrainingSchedule } from 'model';

import { ISitterRegisterForm } from './model';

const DefaultRegisterForm = {
  name: '',
  phone: '',
  hasExperience: true,
  experienceComment: '',
  address: '',
  terms: [false, false, false],
  birthday: '',
  targetName: null,
  targetDate: '',
  targetPlace: '',
  targetMapLink: null,
} as ISitterRegisterForm;

export interface ISitterStore {
  form: ISitterRegisterForm;

  sendVerificationCode(number: string);
  confirmVerificationCode(number: string, code: string);

  getTrainningSchedules(): Promise<ITrainingSchedule[]>;

  resetForm();
  submitRegister(form: ISitterRegisterForm): Promise<void>;
};
const sitterStore = observable<ISitterStore>({
  form: DefaultRegisterForm,

  async sendVerificationCode(number: string) {
    await apiSitter.post(`/account/auth/send`, {
      phone: number,
    });
  },
  async confirmVerificationCode(number: string, code: string) {
    await apiSitter.post(`/account/auth/confirm`, {
      phone: number,
      accessNumber: code,
    });
  },

  async getTrainningSchedules() {
    return await api.get(`/interview`);
  },

  resetForm() {
    this.form = JSON.parse(JSON.stringify(DefaultRegisterForm));
  },
  async submitRegister(form: ISitterRegisterForm) {
    return await api.post(`/hero/application`, {
      ...form,
    });
  },
});
export default sitterStore;
