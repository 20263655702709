import React from 'react';
import styled from 'styled-components';

import { HorizontalLayout, Space, Push } from 'atom/layout';
import { Checkbox } from 'atom/input';
import { Color } from 'constant';
import { ITerms } from 'model';
import { useIsMobile, useTopmost } from 'util/index';
import { TermsPopup } from 'component/popup';

interface TermsProps {
  data: ITerms[];
  value: boolean[];
  onChange: (x: boolean[]) => void;
};
export const Terms = ({
  data,
  value,
  onChange,
}: TermsProps) => {
  const isMobile = useIsMobile();
  const topmost = useTopmost();

  const onClickShow = (type: string) => {
    console.log(type);
    const id = topmost.show((
      <TermsPopup
        type={type}
        onClose={() => topmost.dismiss(id)}
      />
    ));
  };

  return (
    <Container>
      <HorizontalLayout center>
        <Checkbox
          variant="checkmark"
          value={value.every(x => x)}
          onChange={(x) => {
            if (x) onChange(value.map(x => true));
            else onChange(value.map(x => false));
          }}
        />
        <Space width={6} />
        <AcceptAllText>
          전체 동의하기
        </AcceptAllText>
      </HorizontalLayout>
      <Space height={isMobile ? 18 : 45} />

      <hr />
      <Space height={isMobile ? 42: 24} />

      {data.map((x, idx) => (
        <React.Fragment
          key={x.name}
        >
          <TermsItem
            data={x}
            value={value[idx]}
            onClick={() => onClickShow(x.type)}
            onChange={e => {
              value[idx] = e;
              onChange([...value]);
            }}
          />
          <Space height={16} />
        </React.Fragment>
      ))}
    </Container>
  );
};

interface TermsItemProps {
  data: ITerms;
  value: boolean;
  onClick: () => void;
  onChange: (x: boolean) => void;
};
const TermsItem = ({
  data,
  value,
  onClick,
  onChange,
}: TermsItemProps) => {
  return (
    <HorizontalLayout center>
      <Checkbox
        variant="checkmark"
        value={value}
        onChange={onChange}
      />
      <Space width={6} />
      <RequiredText>
        [{data.required ? '필수' : '선택'}]&nbsp;
      </RequiredText>
      <TermsNameText>
        {data.name}
      </TermsNameText>
      <Push />
      <DetailIcon
        onClick={onClick}
      />
    </HorizontalLayout>
  );
};

const AcceptAllText = styled.div`
  color: #484848;

  font-size: 15px;
  font-weight: bold;

  letter-spacing: -0.38px;
`;
const RequiredText = styled.div`  
  color: ${Color.Primary};

  font-size: 13px;
  font-weight: 500;

  letter-spacing: -0.33px;
`;
const TermsNameText = styled.div`
  font-size: 13px;
  font-weight: 500;

  letter-spacing: -0.33px;
`;
const DetailIcon = styled.img.attrs({
  src: require('asset/icon/detail.png').default,
})`
  width: 42px;
  height: 18px;

  cursor: pointer;
  user-select: none;
`;

const Container = styled.div`
`;
