import React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'atom/input';
import { Space, Push, VerticalLayout } from 'atom/layout';
import { AddressPopup } from 'component/popup';
import { useTopmost } from 'util/index';

interface AddressItemProps {
  active?: boolean;
  data: string;
  onChange?: (x: string) => void;
  onRemove?: () => void;
};
export const AddressItem = ({
  active = false,
  data,
  onChange,
  onRemove,
}: AddressItemProps) => {
  const topmost = useTopmost();

  const onClickEdit = () => {
    const id = topmost.show((
      <AddressPopup
        value=""
        onSubmit={(x, y) => onChange(x + ' ' + y)}
        onClose={() => topmost.dismiss(id)}
      />
    ));
  };

  return (
    <Container>
      <Checkbox
        value={true}
        onChange={() => {}}
      />
      <Space width={22} />
      <InnerText>
        {data}
      </InnerText>
      <Push />
      <Space width={32} />
      <VerticalLayout>
        <Icon
          src={require('asset/icon/remove.png').default}
          onClick={onRemove}
        />
        <Space height={10} />
        <Icon
          src={require('asset/icon/edit.png').default}
          onClick={onClickEdit}
        />
      </VerticalLayout>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 110px;

  background: white;
  border-radius: 10px;
  box-shadow: 3px 4px 12px 0 #dedede;

  padding: 24px 28px;
`;
const InnerText = styled.div`
  color: #484848;

  font-size: 16px;
  font-weight: 500;

  line-height: 1.63;
  letter-spacing: -0.4px;
`;
const Icon = styled.img`
  width: 20px;

  cursor: pointer;
`;
