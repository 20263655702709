import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';

import { HorizontalLayout, Push, Space } from 'atom/layout';
import { Terms } from 'atom/terms';
import { useStores } from 'state';
import { CustomerRequestTerms } from 'content';
import { Title, Subtitle } from './style';
import { RegisterStep3 } from './RegisterStep3';

export const RegisterStep2 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [value, setValue] = useState([false, false, false]);

  const isValid = value[0] && value[1];

  useEffect(() => {
    customerStore.signUpForm.isAcceptTerms = value[0] ? 'Y' : 'N';
    customerStore.signUpForm.isPrivacyPolicy = value[1] ? 'Y' : 'N';
    customerStore.signUpForm.isMarketing = value[2] ? 'Y' : 'N';
  }, [value]);

  return (
    <>
      <Title>약관 및 정책에 동의해 주세요.</Title>
      <Space height={87} />

      <Terms data={CustomerRequestTerms} value={value} onChange={setValue} />
      <Space height={50} />
      <Bottom
        disabled={!isValid}
        progress={(1 / 3) * 2}
        onClickNext={() => onNext(RegisterStep3)}
      />
    </>
  );
});
