import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { HorizontalLayout } from 'atom/layout';
import { DatePickerPopup } from 'component/popup';
import { useIsMobile, useTopmost } from 'util/index';
import { DatePicker } from './DatePicker';

interface DateInputProps {
  hasIcon?: boolean;
  value: DateTime;
  onChange: (x: DateTime) => void;
};
export const DateInput = ({
  hasIcon = false,
  value,
  onChange,
}: DateInputProps) => {
  const isMobile = useIsMobile();
  const topmost = useTopmost();
  const [show, setShow] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: (e) => {
      // @ts-ignore
      if (e.target.tagName !== 'ABBR') {
        setShow(false);
      }
    },
  });

  const onClickOpen = () => {
    if (isMobile) {
      const id = topmost.show((
        <DatePickerPopup
          value={value}
          onChange={(v) => onChange(v)}
          onClose={() => topmost.dismiss(id)}
        />
      ));
    } else {
      setShow(true);
    }
  };

  return (
    <Container ref={ref}>
      <HorizontalLayout>
        <TextField
          placeholder="YYYY-MM-DD"
          variant="outlined"
          style={{ width: hasIcon ? 'calc(100% - 30px)' : '100%' }}
          InputLabelProps={{
            shrink: true,
          }}
          value={value?.toFormat('yyyy-MM-dd')}
          onClick={onClickOpen}
        />
        {hasIcon && (
          <CalendarIcon
            onClick={onClickOpen}
          />
        )}
      </HorizontalLayout>
      {show && (
        <DatePicker
          value={value}
          onChange={(value) => {
            onChange(value);
            setShow(false);
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`

  input {
    caret-color: transparent !important;
  }
`;
const CalendarIcon = styled.img.attrs({
  src: require('asset/icon/calendar.svg').default,
})`
  width: 20px;
  height: 20p;

  margin-left: 10px;

  cursor: pointer;
`;
