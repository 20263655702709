import React from 'react';

type PushProps = {
  maxWidth?: number;
  maxHeight?: number;
};
export const Push = ({
  maxWidth,
  maxHeight,
  ...props
}: PushProps) => {
  return (
    <div
      style={{ flex: 1, maxWidth, maxHeight }}
      {...props}
    />
  );
};
