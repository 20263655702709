import React from 'react';

import { Table } from './Table';

export const ServiceCategoryTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th>정기 돌봄</th>
          <th>비정기 돌봄</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            한 달 이상
            <br />
            (4주)
          </td>
          <td>1회 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};
export const HourlyPricingTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th>인원</th>
          <th>가격</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>아이 1명</td>
          <td>13,000원</td>
        </tr>
        <tr>
          <td>아이 2명</td>
          <td>16,000원</td>
        </tr>
      </tbody>
    </Table>
  );
};
export const ServiceMinTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th>서비스 종류</th>
          <th>최소시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>등원 + 놀이돌봄</td>
          <td>2시간 이상</td>
        </tr>
        <tr>
          <td>하원 + 놀이돌봄</td>
          <td>3시간 이상</td>
        </tr>
        <tr>
          <td>등하원 + 놀이돌봄</td>
          <td>5시간 이상</td>
        </tr>
        <tr>
          <td>놀이돌봄만</td>
          <td>3시간 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};
