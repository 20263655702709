import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import styled from 'styled-components';

interface PaperProps {
  style?: CSSProperties;
  children: React.ReactNode;
};
export const Paper = ({
  style,
  children,
}: PaperProps) => {
  const ref = useRef<HTMLDivElement>();
  const [height, setHeight] = useState(-1);

  /*
  useEffect(() => {
    setHeight(ref.current.offsetHeight + 100);
  }, [children]);
  */

  return (
    <Container
      style={style}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 656px;

  padding: 50px 40px;

  border: solid 1px #e5e5e5;
  background: white;

  transition: all 0.33s linear;
`;
