import { observable } from 'mobx';
import { DateTime } from 'luxon';
import { omit } from 'lodash';

import { IChild, IUser } from 'model';
import { ICustomerRequestForm, ICustomerSignUpForm } from './model';
import versionStore from './version';

const DefaultRequestForm = {
  careKind: '정기',
  careService: '',
  careRequest: '',

  schedules: [],
  careTypes: [],
  pickupMethods: [],
  children: [],
  pets: [],
  family: [],

  premium: false,
  cleaning: false,
  cleaningDetail: '',
  heroTypes: [],
} as ICustomerRequestForm;

const DefaultSignUpForm = {
  email: '',
  name: '',
} as ICustomerSignUpForm;

const DefaultChildForm = {
  name: '',
  birthday: '',
  gender: 'F',
  info: '',
} as IChild;

export interface ICustomerStore {
  user: IUser;
  form: ICustomerRequestForm;
  signUpForm: ICustomerSignUpForm;
  childForm: IChild;

  // getters
  isLoggedIn: boolean;

  resetForm();
  resetSignUpForm();
  resetChildForm();

  submitRequest(form: ICustomerRequestForm): Promise<void>;

  sendVerificationCode(phone: string);
  signIn(phone: string, code: string);
  signUp(form: ICustomerSignUpForm);
  update(data: Partial<IUser>): Promise<void>;
  refreshMe(): Promise<any>;

  getCares(): Promise<any[]>;
  getChildren(): Promise<IChild[]>;
  removeChild(id: number);
  addChild(child: IChild);

  // terms
  getTerms(type: string);
};
const customerStore = observable<ICustomerStore>({
  user: null,
  form: DefaultRequestForm,
  signUpForm: DefaultSignUpForm,
  childForm: DefaultChildForm,

  get isLoggedIn() {
    return !!this.user;
  },

  resetForm() {
    this.form = JSON.parse(JSON.stringify(DefaultRequestForm));
  },
  resetSignUpForm() {
    this.signUp = DefaultSignUpForm;
  },
  resetChildForm() {
    this.childForm = DefaultChildForm;
  },
  async submitRequest(form: ICustomerRequestForm) {
    return await api.post(`/care`, {
      ...form,
    });
  },

  async sendVerificationCode(phone: string) {
    return await api.post(`/account/accessNumber`, {
      phone,
    });
  },
  async signIn(phone: string, code: string) {
    api.token = null;
    // status1: "인증번호가 일치하지않습니다."
    // status0: "입력하신 전화번호는 없는 전화번호입니다."
    const {
      message,
      _token_: token,
    } = await api.post(`/account/signin`, {
      accessNumber: code,
      fcmToken: "",
      phone,
    });

    if (!!token && token !== '') {
      sessionStorage.setItem('token', token);
      api.token = token;
    }

    return {
      message,
    };
  },
  async signUp(form: ICustomerSignUpForm) {
    const {
      message,
      _token_: token,
    } = await api.post(`/account/signup`, {
      ...form,
    });

    if (!!token && token !== '') {
      sessionStorage.setItem('token', token);
      api.token = token;
    }

    return {
      message,
    };
  },

  async update(data: Partial<IUser>) {
    await api.patch(`/account/${this.user.id}`, {
      ...data,
    });
  },
  async refreshMe() {
    this.user = await api.get('/account');
  },
  async getCares() {
    const {
      items,
    } = await api.get(`/care?page=1&size=100`);
    return items.filter(x => x.status !== 'end' && x.status !== 'cancel');
  },
  async getChildren() {
    const {
      children,
    } = await api.get(`/care/child?page=1`);
    return children as IChild[];
  },
  async removeChild(id: number) {
    await api.delete(`/care/child/${id}`);
    this.resetChildForm();
    versionStore.increaseVersion('child');
  },
  async addChild(child: IChild) {
    const ret = await api.post(`/care/child`, {
      ...omit(child, 'id'),
      birthday: DateTime.fromFormat(child.birthday, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
    });
    this.resetChildForm();
    return ret;
  },

  // terms
  async getTerms(type: string) {
    return await api.get(`/static/terms/${type}`);
  },
});
export default customerStore;
