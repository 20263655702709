import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { RoundButton } from 'atom/button';
import { Gray18, LightGray12, LightGray10, Gray25 } from 'atom/text';
import { HorizontalLayout, Space, Push } from 'atom/layout';
import { Header } from 'component/header';
import { Footer } from 'component/footer';
import { Color, URL } from 'constant';
import { Banner } from 'component/banner';
import { usePrices } from 'state/hook/value';
import { price } from 'util/index';

export const CustomerPricingPage = ({}) => {
  const history = useHistory();

  return (
    <>
      <Header />
      <Container>
        <Space height={130} />
        <Title>
          우리동네 돌봄히어로
          <br />
          이용요금
        </Title>
        <Space height={55} />

        <Gray25 bold center>
          기본 서비스
        </Gray25>
        <Space height={32} />

        <SectionText>서비스 구분</SectionText>
        <Space height={18} />
        <ServiceCategoryTable />
        <Space height={28} />

        <SectionText>시간당 요금</SectionText>
        <Space height={18} />
        <HourlyPricingTable />
        <Space height={12} />
        <LightGray12 right style={{ width: '619px', fontSize: '11px' }}>
          *공휴일/주말/야간 이용시 2,000원이 추가됩니다.
          <br />
          *선생님 경력에 따라 돌봄료가 달라질 수 있습니다.
        </LightGray12>
        <Space height={77} />

        <SectionText>서비스 종류 및 최소시간</SectionText>
        <Space height={18} />
        <ServiceMinTable />
        <Space height={50} />

        <Gray25 bold center>
          추가 서비스
        </Gray25>
        <Space height={32} />

        <SectionText>서비스 종류 및 최대시간</SectionText>
        <Space height={18} />
        <ExtraServiceMinTable />
        <Space height={18} />
        <LightGray10 style={{ width: '620px', textAlign: 'right' }}>
          *비정기 돌봄 신청시 이용이 불가합니다.
          <br />
          *추가 서비스는 기본돌봄 연계시 이용이 가능합니다.
          <br />
          *창의 놀이의 경우 최대 주 2회까지만 신청이 가능합니다.
        </LightGray10>
        <Space height={50} />

        <SectionText>시간당 요금</SectionText>
        <Space height={18} />
        <ExtraHourlyPricingTable />
        <Space height={18} />
        <LightGray10 style={{ width: '620px', textAlign: 'right' }}>
          *공휴일/주말/야간 이용시 2,000원이 추가됩니다.
          <br />
          *서비스 시작시 최초 1회 필수 준비물 비용 30,000원이 부과됩니다.
        </LightGray10>
        <Space height={77} />

        <Gray18>
          - 정기돌봄의 경우, 한달(4주)이상 이용할 경우 해당됩니다
          <br />
          - 최소시간에 맞출 수 없는 경우 히어로 센터에 개별 문의해주세요
          <br />- 22:00시부터 7:00까지는 야간돌봄으로 필요할 경우 센터에 개별
          문의해주세요
        </Gray18>
        <Space height={72} />

        <HorizontalLayout fill>
          <RoundButton onClick={() => history.push('/service/apply')}>
            돌봄서비스 신청
          </RoundButton>
          <Space width={18} />
          <RoundButton variant="white" onClick={() => window.open(URL.Kakao)}>
            카카오톡 상담하기
          </RoundButton>
        </HorizontalLayout>
        <Space height={220} />

        <Banner />
      </Container>
      <Footer />
    </>
  );
};

const ServiceCategoryTable = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>정기 돌봄</th>
          <th>비정기 돌봄</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            한 달 이상
            <br />
            (4주)
          </td>
          <td>1회 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};
const HourlyPricingTable = () => {
  const prices = usePrices();

  return (
    <Table>
      <thead>
        <tr>
          <th>서비스 시간</th>
          <th>아이 1명</th>
          <th>아이 2명</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>정기</td>
          <td>{price(prices['정기_신입_1:1_day'])}원</td>
          <td>{price(prices['정기_신입_1:2_day'])}원</td>
        </tr>
        <tr>
          <td>비정기</td>
          <td>{price(prices['비정기_신입_1:1_day'])}원</td>
          <td>{price(prices['비정기_신입_1:2_day'])}원</td>
        </tr>
      </tbody>
    </Table>
  );
};
const ServiceMinTable = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>서비스 종류</th>
          <th>최소시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>등원 + 놀이돌봄</td>
          <td>2시간 이상</td>
        </tr>
        <tr>
          <td>하원 + 놀이돌봄</td>
          <td>3시간 이상</td>
        </tr>
        <tr>
          <td>등하원 + 놀이돌봄</td>
          <td>5시간 이상</td>
        </tr>
        <tr>
          <td>놀이돌봄만</td>
          <td>3시간 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};
const ExtraHourlyPricingTable = () => {
  const prices = usePrices();

  return (
    <Table>
      <thead>
        <tr>
          <th>서비스 종류</th>
          <th>이용요금</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>창의 놀이 1명</td>
          <td>{price(prices['정기_premium_1:1_day'])}원</td>
        </tr>
        <tr>
          <td>창의 놀이 2명</td>
          <td>{price(prices['정기_premium_1:2_day'])}원</td>
        </tr>
        <tr>
          <td>아이중점 가사서비스</td>
          <td>{price(prices['정기_cleaning_1:1_day'])}원</td>
        </tr>
      </tbody>
    </Table>
  );
};
const ExtraServiceMinTable = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>서비스 종류</th>
          <th>최대 이용시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>창의 놀이</td>
          <td>1시간</td>
        </tr>
        <tr>
          <td>아이중점 가사서비스</td>
          <td>1시간</td>
        </tr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 619px;

  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  td,
  th {
    border: 1px solid white;
  }
  thead {
    background: ${Color.Primary};
  }
  tbody tr:nth-child(2n + 1) {
    background: #f5f5f5;
  }
  tbody tr:nth-child(2n + 2) {
    background: #f9f9f9;
  }
  th {
    color: white;

    font-size: 14px;
    font-weight: bold;

    padding: 10px 10px;
  }
  td {
    color: #484848;

    font-size: 14px;
    font-weight: 500;
    text-align: center;

    line-height: 1.43;
    letter-spacing: -0.7px;

    padding: 20px 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;
const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;

  line-height: 1.38;
  letter-spacing: -2px;
`;
const SectionText = styled.div`
  color: #484848;

  font-size: 19px;
  font-weight: bold;
  text-align: center;
`;
