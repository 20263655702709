import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { VerticalLayout, Space, HorizontalLayout, Push } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { Gray16, Gray24, LightGray12, Gray25, White24 } from 'atom/text';
import { ImageBox } from 'atom/image';
import { HourlyPricingTable, ServiceMinTable } from 'component/customer';
import { useIsMobile } from 'util/index';

interface IntroBasicPageProps {
  sheet?: boolean; // 튀어나온 시트 모드인지
  injectedHistory?: any;
  onClickApply?: () => void;
  onClose?: () => void;
}
export const IntroBasicPage = ({
  sheet,
  injectedHistory,
  onClickApply,
  onClose,
}: IntroBasicPageProps) => {
  const isMobile = useIsMobile(true);
  const history = useHistory() || injectedHistory;

  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/customer/service/basic/top.jpg').default}
          imageStyle={{ filter: 'brightness(0.8)' }}
          minHeight={isMobile ? 240 : 380}
          maxHeight={isMobile ? 240 : 380}
        >
          <Space height={isMobile ? 40 : 126} />
          <HorizontalLayout>
            <Space width={isMobile ? 40 : 100} />
            <VerticalLayout>
              <White24 bold style={{ fontSize: isMobile ? '24px' : '26px' }}>
                히어로 선생님과 함께하는
                <br />
                즐거운{' '}
                <span style={{ color: '#f69F30' }}>등하원 및 놀이돌봄</span>
              </White24>
              <Space height={isMobile ? 32 : 52} />
              <RoundButton
                variant="primary"
                style={{ padding: '0px 22px' }}
                onClick={() => history.push('/service/apply')}
              >
                서비스 신청하기
              </RoundButton>
            </VerticalLayout>
          </HorizontalLayout>
        </ImageBox>

        <VerticalLayout center style={{ width: '100%', background: '#f9f9f9' }}>
          <SVG
            src={
              isMobile
                ? require('asset/customer/service/basic/intro_mobile.svg')
                    .default
                : require('asset/customer/service/basic/intro.svg').default
            }
            style={{ maxWidth: '100vw', overflow: 'visible' }}
          />
        </VerticalLayout>

        <VerticalLayout center style={{ width: '100%', background: 'white' }}>
          <SVG
            src={
              isMobile
                ? require('asset/customer/service/basic/habbits_mobile.svg')
                    .default
                : require('asset/customer/service/basic/habbits.svg').default
            }
            style={{ maxWidth: '100vw', overflow: 'visible' }}
          />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{
            width: 'calc(100%)',
            background: 'rgba(246, 159, 48, 0.04)',
          }}
        >
          <Space height={64} />
          <Gray24 center bold>
            등하원 및 놀이돌봄은 <br />
            이렇게 진행돼요!
          </Gray24>
          <Space height={32} />

          <HorizontalLayout>
            <SVG
              src={require('asset/customer/service/basic/flow.svg').default}
              style={{ width: '55px', overflow: 'visible' }}
            />
            <Space width={8} />
            <VerticalLayout style={{ transform: 'translateY(-5px)' }}>
              <Space height={2} />
              <Gray16 bold>히어로 선생님과 함께하는 등하원/등하교</Gray16>
              <Space height={22} />
              <FlowImage
                src={require('asset/customer/service/basic/flow1.jpg').default}
              />
              <Space height={33} />

              <Gray16 bold>깔끔한 손 씻기부터 식사 및 간식지도</Gray16>
              <Space height={34} />
              <FlowImage
                src={require('asset/customer/service/basic/flow2.jpg').default}
              />
              <Space height={30} />

              <Gray16 bold>장난감으로 선생님과 재미있는 놀이 진행</Gray16>
              <Space height={35} />
              <FlowImage
                src={require('asset/customer/service/basic/flow3.jpg').default}
              />
              <Space height={43} />
            </VerticalLayout>
          </HorizontalLayout>
        </VerticalLayout>

        <VerticalLayout
          center
          style={{
            width: 'calc(100% - 80px)',
            position: 'static',
            background: 'white',
          }}
        >
          <Space height={64} />
          <Gray25 bold center>
            등하원 및 놀이돌봄
            <br />
            최소 이용시간 안내
          </Gray25>
          <Space height={32} />
          <ServiceMinTable />
          <Space height={64} />

          <Gray25 bold center>
            시간당 요금
          </Gray25>
          <Space height={32} />
          <HourlyPricingTable />
          <Space height={12} />

          <LightGray12 right style={{ width: '100%', maxWidth: '400px' }}>
            *비정기의 경우 시간당 2000원이 추가됩니다.
          </LightGray12>
          <Space height={32} />

          <RoundButton
            variant="primary"
            style={
              isMobile
                ? {
                    position: 'fixed',
                    width: 'calc(100% - 40px)',
                    bottom: '20px',
                  }
                : {
                    width: '100%',
                    borderRadius: '0px',
                    position: 'absolute',
                    bottom: '0px',
                  }
            }
            onClick={() => {
              if (onClickApply) onClickApply();
              else if (sheet) onClose?.();
              else history.push('/service/apply');
            }}
          >
            서비스 신청하기
          </RoundButton>
          <Space height={52} />
        </VerticalLayout>
      </Container>
    </>
  );
};

const Container = styled(VerticalLayout)`
  width: calc(100% + 2px);
  position: static;

  background: white;

  overflow-x: hidden;
`;
const FlowImage = styled.img`
  width: 240px;
  height: 160px;

  image-rendering: -webkit-optimize-contrast;
`;
