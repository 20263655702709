import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Space } from 'atom/layout';
import { UnderlinedText } from 'atom/text';
import { ChildPopup } from 'component/popup';
import { useChildren } from 'state/hook';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { IChild } from 'model';
import { Title, Subtitle } from './style';
import { ChildItem, NoChildrenData } from 'atom/child';
import { RequestConfirm } from './RequestConfirm';
import { RequestStep9 } from './RequestStep9';
import { RequestChildInfo1 } from './RequestChildInfo1';

export const RequestStep8 = observer(({
  Bottom,
  onNext,
}) => {
  const topmost = useTopmost();
  const { customerStore } = useStores();
  const children = useChildren();

  const onRemoveChild = async (x: IChild) => {
    showYesNoPopup('해당 정보를 삭제하면\n다시 등록하셔야해요.\n정말 삭제하시겠어요?', async () => {
      await customerStore.removeChild(x.id);
    });
  };

  useEffect(() => {
    if (!children) return;
    customerStore.form.fstChildId = children[0]?.id;
    customerStore.form.sndChildId = children[1]?.id;
    customerStore.form.careForm = children.length === 1 ? '1:1' : '1:2';
  }, [children]);

  console.log(customerStore.form);

  return (
    <>
      <Title>
        아이 정보를 <br />
        입력해주세요.
      </Title>
      <Space height={8} />
      <Subtitle>
        *최대 두 명의 아이까지 돌봄이 가능해요.
      </Subtitle>
      <Space height={50} />

      {children?.length === 0 && (
        <NoChildrenData />
      )}
      {children?.map((x, idx) => (
        <React.Fragment
          key={idx}
        >
          <ChildItem
            data={x}
            onEdit={async () => {
              customerStore.childForm = {
                ...x,
                birthday: x.birthday.replace(/-/g, ''),
              };
              onNext(RequestChildInfo1);
            }}
            onRemove={() => onRemoveChild(x)}
          />
          <Space height={20} />
        </React.Fragment>
      ))}
      <Space height={21} />

      {children?.length < 2 && (
        <UnderlinedText
          onClick={() => {
            customerStore.resetChildForm();
            onNext(RequestChildInfo1);
          }}
        >
          + 아이 추가하기
        </UnderlinedText>
      )}
      <Space height={60} />
      <Bottom
        disabled={!children || children?.length === 0}
        progress={1 / 11 * 8}
        onClickNext={() => onNext(RequestStep9)}
      />
    </>
  );
});
