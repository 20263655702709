import React, { useEffect } from 'react';

export const useFadeClasses = () => {
  useEffect(() => {
    const applyAnimation = (name: string) => {
      const els = document.getElementsByClassName(name);
      const handler = (e) => {
        // @ts-ignore
        [...els].forEach(el => {
          const { top } = el.getBoundingClientRect();
          if (top - window.innerHeight * 0.9 <= 0) {
            el.classList.add('animate__' + name);
            el.classList.add('animate__animated');
          }
        });
      };
      window.addEventListener('scroll', handler);
      return handler;
    };

    const h1 = applyAnimation('fadeInLeft');
    const h2 = applyAnimation('fadeInRight');
    const h3 = applyAnimation('fadeInUp');
    const h4 = applyAnimation('fadeInDown');
    return () => {
      window.removeEventListener('scroll', h1);
      window.removeEventListener('scroll', h2);
      window.removeEventListener('scroll', h3);
      window.removeEventListener('scroll', h4);
    };
  }, []);
};
