import { CareType } from 'model';

export const getStartTimePlaceholder = (type: CareType) =>
  ({
    [CareType.DropOff]: '07:00',
    [CareType.PickUp]: '16:00',
    [CareType.PlayOnly]: '16:00',
    [CareType.Cleaning]: '16:00',
    [CareType.Cooking]: '16:00',
    [CareType.Premium]: '16:00',
  }[type]);
export const getEndTimePlaceholder = (type: CareType) =>
  ({
    [CareType.DropOff]: '09:00',
    [CareType.PickUp]: '19:00',
    [CareType.PlayOnly]: '19:00',
    [CareType.Cleaning]: '17:00',
    [CareType.Cooking]: '17:00',
    [CareType.Premium]: '17:00',
  }[type]);

// 최소 이용가능시간
export const getMinimunServiceTime = (type: CareType) =>
  ({
    [CareType.DropOff]: 2,
    [CareType.PickUp]: 3,
    [CareType.PlayOnly]: 3,
    [CareType.Cleaning]: 1,
    [CareType.Cooking]: 1,
    [CareType.Premium]: 1,
  }[type]);
// 최대 이용가능시간
export const getMaximumServiceTime = (type: CareType) =>
  ({
    [CareType.DropOff]: 24,
    [CareType.PickUp]: 24,
    [CareType.PlayOnly]: 24,
    [CareType.Cleaning]: 1,
    [CareType.Cooking]: 1,
    [CareType.Premium]: 1,
  }[type]);
