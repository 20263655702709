import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { HorizontalLayout, VerticalLayout, Push, Space } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { ImageBox } from 'atom/image';
import { Black40, Gray16, Gray40, Gray25, LightGray20, LightGray28, White24 } from 'atom/text';
import { Header } from 'component/header';
import { Footer } from 'component/footer';
import { FaqAccordianItem } from 'component/faq';
import { SitterActivityCard, SitterCount, SitterIntroCarousel } from 'component/sitter';
import { Color } from 'constant';
import { useFaq } from 'state/hook';
import { useFadeClasses } from 'util/index';

export const SitterIntroPage = ({

}) => {
  const history = useHistory();
  const faq = useFaq('hero');
  useFadeClasses();

  return (
    <>
      <Header />
      <VerticalLayout center>
        <ImageBox
          src={require('asset/sitter/intro_background.png').default}
          imageStyle={{ filter: 'brightness(0.63)' }}
          minHeight={645}
          maxHeight={654}
        >
          <Space height={160} />
          <HorizontalLayout>
            <Push maxWidth={260} />
            <VerticalLayout>
              <TopTitle>
                당신의 육아 경험도<br />
                <span style={{ color: '#f69F30' }}>
                  경력
                </span>
                입니다.
              </TopTitle>
              <Space height={24} />
              <White24>
                집 근처 30분 거리, 원하는 시간에<br/>
                육아 경험을 살려 히어로 선생님으로 활동해 보세요!
              </White24>
              <Space height={26} />
              <RoundButton
                variant="primary"
                onClick={() => history.push('/hero/apply')}
              >
                선생님 지원하기
              </RoundButton>
            </VerticalLayout>
            <Push />
          </HorizontalLayout>
        </ImageBox>

        <Space height={160} />
        <Quote>
          “히어로를 통해 제 경력을 다시 찾았어요”
        </Quote>
        <Space height={48} />
        <img
          src={require('asset/sitter/intro1.png').default}
          style={{
            width: '588px',
            alignSelf: 'center',
            border: '2px solid #ececec',
            borderRadius: '60px',
            filter: 'drop-shadow(3px 4px 16px #DEDEDE)',
          }}
        />
        <Space height={45} />
        <SmallQuote>
          “우리동네 돌봄히어로를 통해서<br/>
          다시 제가 좋아하는 일을 할 수 있게 되어서 너무 좋아요. <br/>
          <span style={{ color: '#f69F30', fontWeight: 700 }}>
            자라는 아이들을 돌보는 일이 보람있고 행복해요.
          </span>
          ”
        </SmallQuote>
        <Space height={36} />
        <Gray25 bold>
          허평회 히어로 선생님
        </Gray25>
        <Space height={160} />

        <VerticalLayout
          center
          style={{ width: '100%', background: 'rgba(246, 159, 48, 0.04)' }}
        >
          <Space height={160} />
          <SitterCount
          />
          <Space height={63} />
          <Gray40 bold center>
            선생님이 우리동네 돌봄히어로와 함께 하고 있어요.
          </Gray40>
          <Space height={60} />
          <SitterIntroCarousel />
          <Space height={76} />
          <Gray25 center>
            현재 많은 선생님들이 돌봄선생님으로 활동 중에 있어요. <br/>
            여러분의 육아 경험을 경력으로 활용하여 히어로 선생님 되어보세요!
          </Gray25>
          <Space height={160} />
        </VerticalLayout>
        
        <VerticalLayout
          center
          style={{ width: '100%', background: 'white' }}
        >
          <Space height={120} />
          <Black40 bold center>
            히어로 선생님, 이런 분에게 추천해요!
          </Black40>
          <Space height={120} />
          <SVG
            src={require('asset/sitter/recommendations.svg').default}
            style={{ maxWidth: '75vw', overflow: 'visible' }}
          />
          <Space height={160} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: 'rgba(246, 159, 48, 0.04)' }}
        >
          <Space height={120} />
          <Black40 bold center>
            선생님은 어떤일을 하나요?
          </Black40>
          <Space height={24} />
          <LightGray28>
            선생님이 원하는 업무만 선택해서 일하실 수 있어요!
          </LightGray28>
          <Space height={120} />
          <SitterActivityCard
          />
          <Space height={160} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: 'white' }}
        >
          <Space height={120} />
          <Black40 bold center>
            히어로 선생님으로 활동해야하는 이유 <span style={{ color: Color.Primary }}>세가지</span>!
          </Black40>
          <Space height={24} />
          <LightGray28>
            히어로 센터가 선생님의 든든한 지원군이 되어드려요.
          </LightGray28>
          <Space height={76} />
          <SVG
            src={require('asset/sitter/packages.svg').default}
            style={{ maxWidth: '75vw', overflow: 'visible' }}
          />
          <Space height={160} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: '#fafafa' }}
        >
          <Space height={160} />
          <Black40 bold center>
            지금 바로 히어로 선생님에 <span style={{ color: Color.Primary }}>지원</span>해 보세요!
          </Black40>
          <Space height={120} />
          <SVG
            src={require('asset/sitter/apply_steps.svg').default}
            style={{ maxWidth: '75vw', overflow: 'visible' }}
          />
          <Space height={160} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: 'white' }}
        >
          <Space height={160} />
          <Black40 bold center>
            히어로만의 특별한 교육 커리큘럼
          </Black40>
          <Space height={120} />
          <SVG
            src={require('asset/sitter/classes.svg').default}
            style={{ maxWidth: '75vw', overflow: 'visible' }}
          />
          <Space height={160} />
        </VerticalLayout>

        <GrayBackground>
          <Space height={167} />
          <VerticalLayout center>
            <Quote style={{ lineHeight: '72px' }}>
              히어로 선생님에 대해서<br />
              궁금하신 것이 있으신가요?
            </Quote>
            <Space height={100} />
            <FaqContainer>
              {faq.map((x, idx) => (
                <FaqAccordianItem
                  key={idx}
                  data={x}
                />
              ))}
            </FaqContainer>
            <Space height={160} />
          </VerticalLayout>
        </GrayBackground>
        
        {/*}
        <BottomImage>
          <VerticalLayout center>
            <BottomTitle>
              히어로 선생님 지원혜택을<br />
              지인들에게 소개해주세요
            </BottomTitle>
            <Space height={35} />
            <CallCenter>
              고객센터 : 02-6232-0206
            </CallCenter>
            <Space height={35} />
            <RoundButton onClick={()=> {}}>
              친구에게 소개하기
            </RoundButton>
          </VerticalLayout>
        </BottomImage>
              */}
      </VerticalLayout>
      <Footer />
    </>
  );
};

const TopTitle = styled.div`
  color: white;

  font-size: 48px;
  font-weight: bold;

  line-height: 60px;
`;
const Quote = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;

  line-height: 1.38;
  letter-spacing: -2px;
`;
const SmallQuote = styled.div`
  color: #484848;

  font-size: 24px;
  font-weight: 400;
  text-align: center;

  line-height: 32px;
  letter-spacing: -1.13px;
`;
const GrayBackground = styled.div`
  width: 100%;

  background: #f9f9f9;
`
const FaqContainer = styled.div`
  width: 1037px;

  align-self: center;
  background: #f9f9f9;
`;
