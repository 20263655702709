import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
import SVG from 'react-inlinesvg';

import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { Color } from 'constant';

export const SitterActivityCard = ({}) => {
  const [value, setValue] = useState(0);
  const content = Contents[value];

  return (
    <Container>
      <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
        <Tab label="등하원 및 놀이돌봄" />
        <Tab label="창의 놀이" />
        <Tab label="아이중점 가사 서비스" />
      </Tabs>
      <HorizontalLayout style={{ padding: '48px 48px' }}>
        <Thumbnail src={content.image} />
        <Space width={90} />
        <SVG width={520} src={content.content} />
      </HorizontalLayout>

      {/* prefetch 용 */}
      <div style={{ display: 'none' }}>
        {Contents.map((x, idx) => (
          <SVG key={idx} width={450} src={x.content} />
        ))}
      </div>
    </Container>
  );
};

const Contents = [
  {
    image: require('asset/sitter/card1.jpg').default,
    content: require('asset/sitter/card1_content.svg').default,
  },
  {
    image: require('asset/sitter/card2.jpg').default,
    content: require('asset/sitter/card2_content.svg').default,
  },
  {
    image: require('asset/sitter/card3.jpg').default,
    content: require('asset/sitter/card3_content.svg').default,
  },
];

const Container = styled.div`
  width: 1024px;
  height: 443px;

  background: white;
  box-shadow: 3px 4px 16px #dedede;
  border-radius: 24px;

  .MuiTabs-flexContainer {
    height: 94px;

    border-bottom: 1px solid #ececec;
  }
  .MuiTab-root {
    width: 33.33%;
    max-width: initial !important;
  }
  .MuiTab-wrapper {
    font-size: 24px !important;
    font-weight: bold;
    line-height: 32px;
  }
  .Mui-selected .MuiTab-wrapper {
    color: ${Color.Primary} !important;
  }
  .MuiTabs-indicator {
    height: 3px !important;
    background-color: ${Color.Primary} !important;

    transform: scaleX(0.8);
  }
`;
const Thumbnail = styled.img`
  width: 378px;
  height: 252px;

  object-fit: cover;

  border-radius: 16px;
  overflow: hidden;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
