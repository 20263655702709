import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { RoundButton } from 'atom/button';
import { Push, Space, HorizontalLayout, VerticalLayout } from 'atom/layout';
import { Black17, LightGray15, LightGray16 } from 'atom/text';
import { Checkbox } from 'atom/input';
import { useTrainingSchedules } from 'state/hook';
import { useStores } from 'state';
import { ITrainingSchedule } from 'model';
import { Title, Subtitle } from './style';
import { ApplyStep3 } from './ApplyStep3';

export const ApplyStep2 = observer(({
  Bottom,
  onNext,
}) => {
  const { sitterStore } = useStores();
  const schedules = useTrainingSchedules();
  const [schedule, setSchedule] = useState<number>();

  useEffect(() => {
    if (!schedule) return;

    const s = schedules.find(x => x.id === schedule);
    if (s) {
      sitterStore.form.targetDate = s.targetDate;
      sitterStore.form.targetName = s.targetName;
      sitterStore.form.targetPlace = s.targetPlace;
      sitterStore.form.targetMapLink = s.targetMapLink;
    }
  }, [schedule]);

  return (
    <>
      <Title style={{ fontSize: '22px', letterSpacing: '-1.91px' }}>
        참석 가능한 교육 일자를 선택해주세요.
      </Title>
      <Space height={8} />
      <LightGray16>
        *교육을 수료하신 후 원하시는 근거리에서 돌봄이 가능합니다.
      </LightGray16>
      <Space height={45} />
      <img
        style={{ width: '100%' }}
        src={require('asset/mobile/sitter/apply/schedule.svg').default}
      />
      <Space height={34} />

      <hr className="thick" />
      <Space height={34} />

      <InfoBox>
        당일 교육 4시간 진행
      </InfoBox>
      <Space height={45} />

      {schedules.map(x => (
        <React.Fragment
          key={x.id}
        >
          <TrainingCenterItem
            data={x}
            value={schedule === x.id}
            onChange={(e) => {
              if (e)
                setSchedule(x.id);
            }}
          />
          <Space height={20} />
          <hr />
          <Space height={20} />
        </React.Fragment>
      ))}
      <Space height={54} />

      <Bottom
        disabled={!schedule}
        onClickNext={() => onNext(ApplyStep3)}
      />
    </>
  );
});

interface TrainingCenterItemProps {
  data: ITrainingSchedule;
  value: boolean;
  onChange: (x: boolean) => void;
};
const TrainingCenterItem = ({
  data,
  value,
  onChange,
}: TrainingCenterItemProps) => {
  return (
    <HorizontalLayout>
      <VerticalLayout>
        <Black17 bold>
          {data.targetName || '성수 교육장'}
        </Black17>
        <Space height={20} />
        <LightGray15 semiBold>
          교육시간: &nbsp;{data.targetDate}
        </LightGray15>
        <LightGray15 semiBold>
          교육장소: &nbsp;{data.targetPlace}
        </LightGray15>
      </VerticalLayout>
      <Push />
      <Space width={32} />
      <Checkbox
        variant="checkmark"
        value={value}
        onChange={onChange}
      />
    </HorizontalLayout>
  );
};

const InfoBox = styled.div`
  display: flex;
  width: 100%;
  height: 68px;

  font-size: 20px;
  font-weight: 500;

  border-radius: 8px;
  background-color: #f3f4f5;

  align-items: center;
  justify-content: center;
`;
