import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { isNil } from 'lodash';

import { HorizontalLayout, VerticalLayout, Space, Push } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { Progress } from 'atom/progress';
import { Header } from 'component-mobile/header';
import { useStores } from 'state';
import { usePrices } from 'state/hook/value';
import { isIOS } from 'util/index';
import {
  RegisterStep1,
} from './register';
import {
  RequestFaq,
  RequestStep1, RequestStep2, RequestStep3, RequestStep4, RequestStep5Regular,
  RequestStep6, RequestStep7, RequestStep8,
  RequestPersonalityCheck1, RequestPersonalityCheck2, RequestPersonalityCheck3, RequestPersonalityCheck4,
  RequestConfirm,
  RequestDone,
  RequestAlreadyDone,
  RequestChildInfo1,
  RequestStepAdditionalServices,
} from './request';

export const CustomerRequestPage = ({

}) => {
  const history = useHistory();
  const { customerStore } = useStores();
  const [stepHistory, setStepHistory] = useState<React.FC<any>[]>([]);
  const [FormComponent, setFormComponent] = useState<React.FC<any>>();
  const prices = usePrices();

  const onClickPrev = () => {
    if (stepHistory.length === 1) return;
    stepHistory.pop();
    setStepHistory([...stepHistory]);
    setFormComponent(stepHistory[stepHistory.length - 1]);
  };
  const onLogin = async () => {
    try {
      await customerStore.refreshMe();
      if (customerStore.isLoggedIn) {
        const previousCares = await customerStore.getCares();
        if (previousCares.length > 0) {
          setFormComponent(RequestAlreadyDone);
        } else
          setFormComponent(RequestStep1);
      } else
        setFormComponent(RegisterStep1);
    } catch {
      setFormComponent(RegisterStep1);
    }
  };

  useEffect(() => {
    customerStore.resetForm();
    onLogin();
  }, []);
  useEffect(() => {
    if (!FormComponent) return;

    if (stepHistory.includes(FormComponent) === false)
      setStepHistory([...stepHistory, FormComponent]);

    if (FormComponent === RequestStep8) {
      setStepHistory(stepHistory => stepHistory.filter(x => ![
        RequestChildInfo1,
        RequestPersonalityCheck1,
        RequestPersonalityCheck2,
        RequestPersonalityCheck3,
        RequestPersonalityCheck4,
      ].includes(x))); 
    }

    window.history.replaceState(
      { },
      '돌봄 서비스 신청하기',
      FormComponent === RequestConfirm
        ? `/service/apply/done`
        : `/service/apply/q${stepHistory.length + 1}`,
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [FormComponent]);

  return (
    <>
      <Header />
      <Container>
        {!!FormComponent && (
          <FormComponent
            Bottom={(props) => (
              <FormBottom
                progress={10}
                disabled={false}
                onClickPrev={onClickPrev}
                {...props}
              />
            )}
            onLogin={onLogin}
            onNext={setFormComponent}
          />
        )}
      </Container>
    </>
  );
};

interface FormBottomProps {
  progress: number;
  disabled: boolean;
  nextLabel?: string;
  onClickPrev: () => void;
  onClickNext: () => void;
};
const FormBottom = ({
  progress,
  disabled = false,
  nextLabel,
  onClickPrev,
  onClickNext,
}: FormBottomProps) => {
  return (
    <>
      <Push />
      {!isNil(progress) && (
        <>
          <Progress
            value={progress * 100}
          />
          <Space height={24} />
        </>
      )}

      <HorizontalLayout>
        {!!onClickPrev && (
          <>
            <RoundButton
              variant="white"
              onClick={onClickPrev}
            >
              이전
            </RoundButton>
            <Space width={10} />
          </>
        )}
        <RoundButton
          fill
          disabled={disabled}
          onClick={onClickNext}
        >
          {nextLabel || '다음'}
        </RoundButton>
      </HorizontalLayout>
    </>
  );
};

const Container = styled.div.attrs({
  style: {
    minHeight: isIOS ? '-webkit-fill-available' : undefined,
  },
})`
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 56px - 71px);

  justify-content: flex-start;

  padding: 43px 20px;
`;
