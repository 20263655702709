import { observable } from 'mobx';

import { isMobile } from 'util/index';

export interface IBannerStore {
  getBanners();
};
const bannerStore = observable<IBannerStore>({
  async getBanners() {
    return (await api.get(`/monitor/banner`, {
      platform: isMobile() ? 'mobile' : 'pc',
    })).filter(x => x.target !== 'empty');
  }
});
export default bannerStore;
