import React from 'react';
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import { MobileContextProvider } from 'util/index';

import { Drawer } from 'component-mobile/drawer';
import { HomePage } from 'page-mobile/HomePage';
import { CustomerPricingPage } from 'page-mobile/customer/CustomerPricingPage';
import { FaqPage } from 'page-mobile/FaqPage';
import { SitterIntroPage } from 'page-mobile/sitter/SitterIntroPage';
import { SitterApplyPage } from 'page-mobile/sitter/SitterApplyPage';
import { CustomerIntroPage } from 'page-mobile/customer/CustomerIntroPage';
import { CustomerRequestPage } from 'page-mobile/customer/CustomerRequestPage';
import { IntroBasicPage } from 'page-mobile/customer/intro/IntroBasicPage';
import { IntroPremiumPage } from 'page-mobile/customer/intro/IntroPremiumPage';
import { IntroExtraPage } from 'page-mobile/customer/intro/IntroExtraPage';

const ScrollToTop = withRouter(({ history }) => {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});

const NavMobile = () => {
  return (
    <MobileContextProvider isMobile={true}>
      <div className="mobile">
        <BrowserRouter>
          <ScrollToTop />
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            <Route exact path="/" component={HomePage} />
            <Route exact path="/faq" component={FaqPage} />

            {/* Customer */}
            <Route
              exact
              path="/service/pricing"
              component={CustomerPricingPage}
            />
            <Route
              exact
              path="/service/apply"
              component={CustomerRequestPage}
            />
            <Route
              exact
              path="/service/apply/q1"
              component={CustomerRequestPage}
            />
            <Route exact path="/service/intro" component={CustomerIntroPage} />
            <Route
              exact
              path="/service/intro/basic"
              component={IntroBasicPage}
            />
            <Route
              exact
              path="/service/intro/premium"
              component={IntroPremiumPage}
            />
            <Route
              exact
              path="/service/intro/extra"
              component={IntroExtraPage}
            />

            {/* Sitter */}
            <Route exact path="/hero/about" component={SitterIntroPage} />
            <Route exact path="/hero/apply" component={SitterApplyPage} />
            <Route exact path="/hero/apply/q1" component={SitterApplyPage} />

            <Redirect to="/" />
          </AnimatedSwitch>
          <Drawer />
        </BrowserRouter>
      </div>
    </MobileContextProvider>
  );
};
export default NavMobile;
