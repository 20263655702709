import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { DesiredPlays } from 'constant';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestPersonalityCheck4 } from './RequestPersonalityCheck4';

export const RequestPersonalityCheck3 = observer(({
  Bottom,
  onNext,
}) => {
  const { customerStore } = useStores();
  const [value, setValue] = useState<number[]>([]);

  useEffect(() => {
    if (!customerStore.childForm.needGame) return;

    setValue(customerStore.childForm.needGame.split('\n')
      .map(x => DesiredPlays.find(y => y.label === x)?.value),
    );
  }, []);
  useEffect(() => {
    customerStore.childForm.needGame = 
      value.map(x => DesiredPlays.find(y => y.value === x).label).join('\n');
  }, [value]);

  return (
    <>
     <Title>
        우리 아이에게 필요한<br/>
        놀이는 무엇일까요?
      </Title>
      <Space height={8} />
      <Subtitle>
        *중복으로 선택이 가능합니다.
      </Subtitle>
      <Space height={41} />

      <MultiSelect
        items={DesiredPlays}
        value={value}
        onChange={setValue}
      />
      <Space height={60} />
      <Bottom
        disabled={value.length === 0}
        progress={1 / 5 * 4}
        onClickNext={() => onNext(RequestPersonalityCheck4)}
      />
    </>
  );
});
