import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface ImageBoxProps {
  src: any;
  minHeight: number,
  maxHeight?: number;
  style?: CSSProperties;
  imageStyle?: CSSProperties;
  children: React.ReactNode;
};
export const ImageBox = ({
  src,
  minHeight,
  maxHeight,
  style,
  imageStyle,
  children,
}: ImageBoxProps) => {
  return (
    <Container>
      <InnerImage
        src={src}
        style={imageStyle}
        minHeight={minHeight}
        maxHeight={maxHeight}
      />
      <InnerContent
        style={style}
      >
        {children}
      </InnerContent>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
`;
const InnerImage = styled.img<any>`
  width: 100%;
  height: auto;

  object-fit: cover;

  ${({ minHeight }) => `
    min-height: ${minHeight}px;
  `}
  ${({ maxHeight }) => maxHeight ? `
    max-height: ${maxHeight}px;
  ` : ``}
`;
const InnerContent = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;

  width: 100%;
  height: 100%;
`;
