import React from 'react';
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import { MobileContextProvider } from 'util/index';

import { HomePage } from 'page/HomePage';
import { CustomerPricingPage } from 'page/customer/CustomerPricingPage';
import { CustomerRequestPage } from 'page/customer/CustomerRequestPage';
import { CustomerIntroPage } from 'page/customer/CustomerIntroPage';
import { SitterIntroPage } from 'page/sitter/SitterIntroPage';
import { FaqPage } from 'page/FaqPage';
import { SitterApplyPage } from 'page/sitter/SitterApplyPage';
import { IntroBasicPage } from 'page/customer/intro/IntroBasicPage';
import { IntroPremiumPage } from 'page/customer/intro/IntroPremiumPage';
import { IntroExtraPage } from 'page/customer/intro/IntroExtraPage';

const ScrollToTop = withRouter(({ history }) => {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});

const NavPC = () => {
  return (
    <MobileContextProvider isMobile={false}>
      <style>
        {`#root {
          min-width: 800px;
        }`}
      </style>
      <BrowserRouter>
        <ScrollToTop />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route exact path="/" component={HomePage} />
          <Route exact path="/faq" component={FaqPage} />

          {/* Customer */}
          <Route
            exact
            path="/service/pricing"
            component={CustomerPricingPage}
          />
          <Route exact path="/service/apply" component={CustomerRequestPage} />
          <Route
            exact
            path="/service/apply/q1"
            component={CustomerRequestPage}
          />
          <Route exact path="/service/intro" component={CustomerIntroPage} />
          <Route exact path="/service/intro/basic" component={IntroBasicPage} />
          <Route
            exact
            path="/service/intro/premium"
            component={IntroPremiumPage}
          />
          <Route exact path="/service/intro/extra" component={IntroExtraPage} />

          {/* Sitter */}
          <Route exact path="/hero/about" component={SitterIntroPage} />
          <Route exact path="/hero/apply" component={SitterApplyPage} />
          <Route exact path="/hero/apply/q1" component={SitterApplyPage} />

          <Redirect to="/" />
        </AnimatedSwitch>
      </BrowserRouter>
    </MobileContextProvider>
  );
};
export default NavPC;
