import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

import { Black14 } from 'atom/text';
import { Space, HorizontalLayout } from 'atom/layout';
import { Color } from 'constant';
import { useTerms } from 'state/hook';
import { Backdrop } from './Backdrop';

interface TermsPopupProps {
  type: string;
  onClose: () => void;
};
export const TermsPopup = ({
  type,
  onClose,
}: TermsPopupProps) => {
  const terms = useTerms(type);

  return (
    <Backdrop
      onClose={onClose}
    >
      <Container
        onClick={e => e.stopPropagation()}
      >
        <TermsContainer
          dangerouslySetInnerHTML={{ __html: terms }}
        />
        <BottomButton
          onClick={onClose}
        >
          확인
        </BottomButton>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  width: 480px;
  max-width: calc(100vw - 40px);

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  --animate-duration: 0.4s;
`;
const TermsContainer = styled.pre`
  width: 100%;
  height: 500px;

  white-space: break-spaces;

  overflow-y: auto;

  padding: 20px 20px;
`;
const BottomButton = styled.div`
  display: flex;
  width: 100%;
  height: 52px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
