import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Space } from 'atom/layout';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestConfirm } from './RequestConfirm';
import { RegisterStep2 } from '../register';

export const RequestStep11 = observer(({
  Bottom,
  onNext,
}) => { 
  const { customerStore } = useStores();

  const onClickNext = () => {
    if (!customerStore.user.name) {
      onNext(RegisterStep2);
    } else {
      onNext(RequestConfirm);
    }
  };

  return (
    <>
      <Title>
        돌봄 중 요청사항을<br />
        적어주세요.
      </Title>
      <Space height={8} />
      <Subtitle>
        *성향, 병력, 낮잠시간, 기저귀 착용여부 등<br/>
        &nbsp;자유롭게 적어주세요.<br/>
        *아이 외부 스케쥴도 함께 적어주세요.
      </Subtitle>
      <Space height={51} />

      <textarea
        placeholder="입력란 (200자 이내)"
        maxLength={200}
        value={customerStore.form.careRequest}
        onChange={e => customerStore.form.careRequest = e.target.value}
      />
      <Space height={60} />
      <Bottom
        disabled={customerStore.form.careRequest.length === 0}
        progress={1 / 11 * 11}
        onClickNext={onClickNext}
      />
    </>
  );
});
