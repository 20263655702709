import React from 'react';
import styled from 'styled-components';

import { usePrices } from 'state/hook/value';
import { Table } from './Table';
import { price } from 'util/index';

export const ExtraServiceMinTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th>서비스 종류</th>
          <th>최대 이용 시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>기본 놀이돌봄 연계시</td>
          <td>1시간</td>
        </tr>
      </tbody>
    </Table>
  );
};
export const ExtraServiceHourlyPricingTable = () => {
  const prices = usePrices();

  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th>이용 시간</th>
          <th>가격</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1시간</td>
          <td>{price(prices['정기_cleaning_1:1_day'])}원</td>
        </tr>
      </tbody>
    </Table>
  );
};
