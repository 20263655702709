import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import { HorizontalLayout, VerticalLayout, Space, Push } from 'atom/layout';
import { Gray12, Gray14, Gray25, Gray34, LightGray12 } from 'atom/text';
import { ISitterIntroContent, SitterIntroContents } from 'content';
import { useHeroIntro } from 'state/hook/value';
import { useIsMobile } from 'util/index';

interface SitterIntroCarouselProps {}
export const SitterIntroCarousel = ({}: SitterIntroCarouselProps) => {
  const isMobile = useIsMobile();
  const hero = useHeroIntro();

  return (
    <Container isMobile={isMobile}>
      <Slider
        //autoplay
        autoplaySpeed={2000}
        centerMode
        variableWidth
        adaptiveHeight
        dots
      >
        {SitterIntroContents.map((x, idx) => (
          <SitterIntroCard key={idx} data={x} />
        ))}
      </Slider>
    </Container>
  );
};

interface SitterIntroCardProps {
  data: ISitterIntroContent;
}
const SitterIntroCard = ({ data }: SitterIntroCardProps) => {
  const isMobile = useIsMobile();

  const Title = isMobile ? Gray14 : Gray34;
  const Subtitle = isMobile ? LightGray12 : Gray25;

  return (
    <CardContainer isMobile={isMobile}>
      <HorizontalLayout center>
        <PortraitContainer isMobile={isMobile}>
          <Portrait src={data.image} />
        </PortraitContainer>
        <Space width={isMobile ? 20 : 64} />
        <VerticalLayout center={!isMobile} style={{ flex: 1 }}>
          <Title style={{ fontWeight: isMobile ? 'bold' : 700 }}>
            {data.name}
          </Title>
          <Space height={isMobile ? 10 : 20} />
          <Subtitle center={!isMobile}>{data.content}</Subtitle>
        </VerticalLayout>
      </HorizontalLayout>
    </CardContainer>
  );
};

const Container = styled.div<any>`
  width: 100%;
  height: 420px;

  padding-top: 20px;

  overflow: hidden;

  .slick-dots {
    bottom: -72px;

    li {
      width: 24px;
      height: 24px;
    }
    li button:before {
      font-size: 18px;
    }
  }

  ${({ isMobile }) =>
    isMobile
      ? `
    height: 180px;

    .slick-dots {
      bottom: -36px;

      li {
        width: 12px;
        height: 12px;
      }
      li button:before {
        font-size: 12px;
      }
    }
  `
      : `
  `}
`;
const CardContainer = styled.div<any>`
  height: 320px;
  align-items: center;

  background: #fff;
  box-shadow: 3px 4px 16px #dedede;
  border-radius: 40px;

  margin-left: 32px;
  margin-right: 32px;

  padding: 37px 80px;

  justify-content: center;
  display: flex;

  ${({ isMobile }) =>
    isMobile
      ? `
    width: 295px;
    height: 110px;

    border-radius: 24px;

    margin-left: 6px;
    margin-right: 6px;

    padding: 12px 11px;
  `
      : `
    width: 906px;
  `}
`;
const PortraitContainer = styled.div<any>`
  width: 250px;
  height: 250px;

  border-radius: 125px;

  overflow: hidden;

  background: #f69f30;

  ${({ isMobile }) =>
    isMobile
      ? `
    width: 64px;
    height: 64px;
  `
      : `
  `}
`;
const Portrait = styled.img<any>`
  width: 100%;
  height: 100%;

  border-radius: 125px;

  overflow: hidden;

  object-fit: cover;
  image-rendering: -webkit-optimize-contrast;
`;
