import React, { useState, useEffect } from 'react';

import { useStores } from 'state';
import { SitterInterviewContent, SitterReviewContent } from 'content';

export const useReviews = () => {
  const { valueStore } = useStores();
  const [data, setData] = useState<SitterReviewContent[]>([]);

  useEffect(() => {
    (async () => {
      setData(await valueStore.getReviews());
    })();
  }, []);

  return data;
};
export const useHeroIntro = () => {
  const { valueStore } = useStores();
  const [data, setData] = useState<SitterInterviewContent[]>([]);

  useEffect(() => {
    (async () => {
      setData(await valueStore.getHeroIntros());
    })();
  }, []);

  return data;
};

export let sharedPrices = null;
export const usePrices = () => {
  const { valueStore } = useStores();
  const [data, setData] = useState<Record<string, number>>(sharedPrices || {});

  useEffect(() => {
    if (sharedPrices) return;

    (async () => {
      const p = await valueStore.getPrices();
      sharedPrices = p;
      setData(p);
    })();
  }, []);

  return data;
};
export const useSiteValue = (key: string) => {
  const { valueStore } = useStores();
  const [data, setData] = useState<string>('');

  useEffect(() => {
    (async () => {
      setData(await valueStore.getValue(key));
    })();
  }, []);

  return data;
};
