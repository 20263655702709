import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { RoundButton } from 'atom/button';
import { Space, Push, HorizontalLayout } from 'atom/layout';
import { Labeled } from 'atom/input';
import { Black16, LightGray14, LightGray15, LightGray16, Gray20, Primary16 } from 'atom/text';
import { Company } from 'constant';
import { useStores } from 'state';
import { Title, Subtitle } from './style';

export const ApplyDone = observer(({
}) => {
  const history = useHistory();
  const { sitterStore } = useStores();
  const form = sitterStore.form;

  console.log(form);

  return (
    <>
      <Title>
        교육 예약이 완료되었습니다!
      </Title>
      <Space height={11} />
      <Gray20 bold>
        교육담당 매니저가<br/>
        다시한번 연락드리겠습니다.
      </Gray20>
      <Space height={34} />
      <hr className="thick" />
      <Space height={32} />

      <NoticeItem
        title="교육일정"
        content={form.targetDate.replace(' / ', '\n')}
        comment="*교육에 불참하게 될 시, 최소 하루 전에 미리 연락주세요."
      />
      <hr />
      <Space height={30} />

      <NoticeItem
        title="교육비"
        content={"교육비 : 3만원\n계좌 : " + Company.BankAccount}
        comment="*교육 하루 전까지는 꼭 입금을 한 뒤 참석해주세요."
      />
      <hr />
      <Space height={24} />

      <NoticeItem
        title="준비물"
        content="신원확인을 위한 신분증 지참\n필기구, 마스크 필수착용"
      />
      <hr />
      <Space height={24} />

      <NoticeItem
        title="교육당일 안내"
        content="교육 당일에 프로필 촬영이 있어요.\n
        자기소개서에 들어갈 사진이니 참고해주세요^^\n
        모자 착용은 하지 말아주세요."
      />
      <hr />
      <Space height={24} />

      <NoticeItem
        title="교육장소"
        content={form.targetName}
        comment={form.targetPlace}
      />
      {!!form.targetMapLink && (
        <iframe src={form.targetMapLink}
          style={{ width: '100%', height: '240px', border: 0 }} loading="lazy">
        </iframe>
      )}

      <Space height={30} />
      <RoundButton
        style={{ width: '100%' }}
        onClick={() => history.replace('/')}
      >
        홈으로 가기
      </RoundButton>
    </>
  );
});

interface NoticeItemProps {
  title: string;
  content?: string;
  comment?: string;
};
const NoticeItem = ({
  title,
  content,
  comment,
}: NoticeItemProps) => {
  return (
    <>
      <Primary16 bold>
        {title.replace(/\\n/g, '\n')}
      </Primary16>
      <Space height={12} />
      {content && (
        <Black16 semiBold>
          {content.replace(/\\n/g, '\n')}
        </Black16>
      )}
      {comment && (
        <>
          <Space height={12} />
          <LightGray14 semiBold>
            {comment.replace(/\\n/g, '\n')}
          </LightGray14>
        </>
      )}
      <Space height={24} />
    </>
  );
};
