import React from 'react';
import styled, { CSSProperties } from 'styled-components';

type HorizontalLayoutProps = {
  center?: boolean;
  fill?: boolean;
  style?: CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
};
export const HorizontalLayout = ({
  center = false,
  fill = false,
  children,
  ...props
}: HorizontalLayoutProps) => {
  return (
    <Container
      center={center}
      fill={fill}
      {...props}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: row;

  ${({ fill }: HorizontalLayoutProps) => fill ? `
    flex: 1;
  ` : `
  `}
  ${({ center }: HorizontalLayoutProps) => center ? `
    align-items: center;
  ` : `
  `}
`;
