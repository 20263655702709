import { observable } from 'mobx';

export interface IVersionStore {
  versions: Record<string, number>;

  getVersion(resource: string);
  increaseVersion(resource: string);
};
const versionStore = observable<IVersionStore>({
  versions: {},

  getVersion(resource: string) {
    return this.versions[resource] || 0;
  },
  increaseVersion(resource: string) {
    if (this.versions[resource])
      this.versions[resource] += 1;
    else
      this.versions[resource] = 1;
  },
});
export default versionStore;
