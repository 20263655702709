import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import { IFaqContent } from 'content';

interface FaqAccordianItemProps {
  data: IFaqContent;
};
export const FaqAccordianItem = ({
  data,
}: FaqAccordianItemProps) => {
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<img src={require('asset/icon/arrow.svg').default} style={{ width: '18px' }} />}
      >
        {data.question}
      </AccordionSummary>
      <Answer>
        <span
          dangerouslySetInnerHTML={{ __html: data.answer }}
        />
        {!!data.image && (
          <InnerImage
            src={data.image}
          />
        )}
      </Answer>
    </CustomAccordion>
  );
};
const CustomAccordion = styled(Accordion)`
  .MuiPaper-root {
    background: #f9f9f9 !important;
  }
`
const Answer = styled(AccordionDetails)`
  white-space: pre-line;

  flex-direction: column;
  gap: 24px;
`;
const InnerImage = styled.img`
  width: 70%;
  height: auto;

  margin-left: auto;
  margin-right: auto;
`;
