import React from 'react';
import styled from 'styled-components';

import { Color } from 'constant';
import { Table } from './Table';

export const CleaningTable = () => {
  return (
    <Table
      align="start"
      fontSize={11}
      style={{ width: '100%', maxWidth: '400px' }}
    >
      <thead>
        <tr>
          <th style={{ width: '50%' }}>가능 업무</th>
          <th style={{ width: '50%' }}>불가능 업무</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            · 방 청소 기본 구역
            <br />
            &nbsp;&nbsp;· 거실 1공간
            <br />
            &nbsp;&nbsp;· 아이 침실/놀이방 1공간 <br />
            &nbsp;&nbsp;· 부엌 바닥 1공간
            <br />
            <br />
            · 청소기 & 먼지통 비우기
            <br />
            <br />
            · 마대 걸레질 & 물티슈 등으로 바닥 먼지, 얼룩 정돈
            <br />
            <br />
            · 아이 침실/놀이방에 있는 돌봄용품 및 아이들 장난감 정리 정돈 <br />
          </td>
          <td>
            · 부모님 방 청소
            <br />
            <br />
            · 청소기 먼지통 물세척
            <br />
            <br />
            · 빗자루질
            <br />
            <br />
            · 손걸레
            <br />
            <br />
            · 먼지 청소(책장, 쇼파, 전자기기 등)
            <br />
            <br />· 장난감 소독
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export const DishWashingTable = () => {
  return (
    <Table
      align="start"
      fontSize={11}
      style={{ width: '100%', maxWidth: '400px' }}
    >
      <thead>
        <tr>
          <th style={{ width: '50%' }}>가능 업무</th>
          <th style={{ width: '50%' }}>불가능 업무</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            · 아이 식기류만 설거지
            <br />
            &nbsp;&nbsp;· 돌봄 중 / 식사 후 발생된 식기류 <br />
            &nbsp;&nbsp;· 어린이집에서 사용한 식판 / 도시락통
            <br />
            <br />
            · 젖병 소독 / 닦기
            <br />
            <br />
            · 설거지하면서 나온 음식 쓰레기 청소 후 지정된 방법으로 보관
            <br />
            <br />· 싱크대 물기 청소 및 주변 청소
          </td>
          <td>
            · 젖병 열탕 소독
            <br />
            <br />
            · 행주 삶기
            <br />
            <br />✓ 서로를 위해 가급적 고가의 그릇은 설거지에 포함되지 않도록
            배려해 주세요.
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export const WashingTable = () => {
  return (
    <Table
      align="start"
      fontSize={11}
      style={{ width: '100%', maxWidth: '400px' }}
    >
      <thead>
        <tr>
          <th style={{ width: '50%' }}>가능 업무</th>
          <th style={{ width: '50%' }}>불가능 업무</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            · 세탁기 및 건조를 사용한 세탁
            <br />
            &nbsp;&nbsp;· 바구니에 따로 분류된 아이 의류 빨래 (아이 속옷, 양말,
            일반 옷 등)
            <br />
            &nbsp;&nbsp;· 빨래 개기/ 바구니에 정리 정돈
            <br />
            <br />
            ✓ 처음 방문 시 세탁기 및 건조기 사용방법을 설명해 주세요.
            <br />
            <br />
            ✓ 사전에 빨래 분류 방법 등을 함께 조율해 주세요. <br />
          </td>
          <td>
            · 어른 빨래 및 신발, 가방, 커튼, 침구류 세탁
            <br />
            <br />
            · 손빨래, 다림질
            <br />
            <br />
            · 세탁물 맡기기 및 수선
            <br />
            <br />
            · 서랍장에 정리
            <br />
            <br />✓ 서로를 위해 가급적 명품 의류는 포함되지 않도록 배려해
            주시고, 세탁 가능한 제품만 담아주세요.
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export const CookingTable = () => {
  return (
    <Table
      align="start"
      fontSize={11}
      style={{ width: '100%', maxWidth: '400px' }}
    >
      <thead>
        <tr>
          <th style={{ width: '50%' }}>가능 업무</th>
          <th style={{ width: '50%' }}>불가능 업무</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            · 밥짓기 및 반찬, 국거리 만들기
            <br />
            &nbsp;&nbsp;· 시간당 반찬 1~2개 및 국 1 가지 가능
            <br />
            <br />· 요리 중에 발생된 식기류 설거지
          </td>
          <td>
            · 압력밥솥 밥짓기
            <br />
            <br />
            · 장보기 <br />
            <br />
            · 요리와 무관한 재료 손질
            <br />
            <br />
            ✓ 원활한 요리를 위해 프라이팬, 냄비 등 쌓여있는 설거지가 없도록
            해주세요.
            <br />
            <br />
            ✓ 서로를 위해 가급적 고가의 조리도구는 포함되지 않도록 배려해
            주세요.
            <br />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
