import React from 'react';
import styled from 'styled-components';

import { Space } from 'atom/layout';
import { Color } from 'constant';
import { Backdrop } from './Backdrop';

interface ConfirmPopupProps {
  message: string;
  comment?: string;
  onClose: () => void;
};
export const ConfirmPopup = ({
  message,
  comment,
  onClose,
}: ConfirmPopupProps) => {
  return (
    <Backdrop
      onClick={onClose}
    >
      <Container
        onClick={e => e.stopPropagation()}
      >
        <TextContainer>
          <InnerText>
            {message}
          </InnerText>
          {!!comment && (
            <>
              <Space height={15} />
              <CommentText>
                {comment}
              </CommentText>
            </>
          )}
        </TextContainer>
        <BottomButton
          onClick={onClose}
        >
          확인
        </BottomButton>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  width: 400px;
  max-width: 90vw;

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  --animate-duration: 0.4s;
`;
const TextContainer = styled.div`
  padding: 30px 30px;
`;
const InnerText = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  line-height: 1.33;
  letter-spacing: -0.45px;
  white-space: break-spaces;
`;
const CommentText = styled.div`
  color: #707070;

  font-size: 13px;
  font-weight: 500;
  text-align: center;

  line-height: 1.46;
  letter-spacing: -0.33px;
  white-space: break-spaces;
`;
const BottomButton = styled.div`
  display: flex;
  width: 100%;
  height: 52px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
