import { observable } from 'mobx';

import { SitterReviewContent, IFaqContent, SitterInterviewContent } from 'content';

export interface IValueStore {
  getValue(key: string): Promise<string>;

  getPrices(): Promise<Record<string, number>>;
  getReviews(): Promise<SitterReviewContent[]>;
  getHeroIntros(): Promise<SitterInterviewContent[]>;
  getFaq(type: string): Promise<IFaqContent[]>;
};
const valueStore = observable<IValueStore>({
  async getValue(key: string) {
    const { value } = await api.get(`/value`, {
      key,
    });
    return value;
  },
  async getReviews() {
    return await api.get(`/content/review`, {
    });
  },
  async getHeroIntros() {
    return await api.get(`/content/intro`, {
    });
  },
  async getPrices() {
    const v = await api.get(`/value/price`, {});
    console.log(v.value);
    return JSON.parse(v.value).customer;
  },
  async getFaq(type: string) {
    const items = await api.get(`/notification/faq`, {
      type,
    });
    return items.filter(x => x.type === type).map(x => ({
      id: x.id,
      question: x.title,
      answer: x.content,
    }));
  },
});
export default valueStore;
