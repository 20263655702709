import React from 'react';
import styled from 'styled-components';

import { IntroExtraPage as InnerPage } from 'page-mobile/customer/intro/IntroExtraPage';
import { Header } from 'component/header';

export const IntroExtraPage = () => {

  return (
    <>
      <Header />
      <Container>
        <Wrap>
          <InnerPage />
        </Wrap>
      </Container>
    </>
  );
};

const Container = styled.div`
  background: #efefef;
`;
const Wrap = styled.div`
  width: 480px;

  background: white;

  margin-left: auto;
  margin-right: auto;
`;
