import React, { useState, useEffect } from 'react';

import { useStores } from 'state';

export const useTerms = (type: string) => {
  const { customerStore } = useStores();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    (async () => {
      setData(await customerStore.getTerms(type));
    })();
  }, []);

  return data;
};
