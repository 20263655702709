import React from 'react';
import styled from 'styled-components';

import { Color } from 'constant';

interface StepIndicatorProps {
  value: number;
  maxStep: number;
};
export const StepIndicator = ({
  value,
  maxStep,
}: StepIndicatorProps) => {
  return (
    <Container>
      {[...new Array(maxStep)].map((x, idx) => (
        <StepItem
          key={idx}
          active={value === idx}
        >
          {idx + 1}
        </StepItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  gap: 5px;
`;
const StepItem = styled.div<any>`
  display: flex;
  width: 20px;
  height: 20px;

  color: white;

  font-size: 10px;
  font-weight: 500;

  border-radius: 10px;
  overflow: hidden;

  align-items: center;
  justify-content: center;

  ${({ active }) => active ? `
    background: ${Color.Primary};
  ` : `
    background: #e5e5e5;
  `}
`;

