import React from 'react';
import styled from 'styled-components';

import { Table } from './Table';

export const PremiumServiceMinTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th>서비스 종류</th>
          <th>최대 이용 시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>창의 놀이</td>
          <td>1시간</td>
        </tr>
      </tbody>
    </Table>
  );
};
export const PremiumServiceHourlyPricingTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th>이용</th>
          <th>가격</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>아이 1명</td>
          <td>20,000원</td>
        </tr>
        <tr>
          <td>아이 2명</td>
          <td>30,000원</td>
        </tr>
      </tbody>
    </Table>
  );
};
