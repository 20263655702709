import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { VerticalLayout, Space, HorizontalLayout, Push } from 'atom/layout';
import { RoundButton } from 'atom/button';
import {
  Black14,
  LightGray12,
  Gray25,
  White24,
  Gray24,
  Gray12,
  Gray16,
} from 'atom/text';
import { ImageBox } from 'atom/image';
import { FaqAccordianItem } from 'component/faq';
import {
  PremiumServiceHourlyPricingTable,
  PremiumServiceMinTable,
} from 'component/customer';
import { useFaq } from 'state/hook';
import { useIsMobile } from 'util/index';
import { Tab } from 'component-mobile/tab';

interface IntroPremiumPageProps {
  sheet?: boolean; // 튀어나온 시트 모드인지
  injectedHistory?: any;
  onClickApply?: () => void;
  onClose?: () => void;
}
export const IntroPremiumPage = ({
  sheet,
  injectedHistory,
  onClickApply,
  onClose,
}: IntroPremiumPageProps) => {
  const isMobile = useIsMobile(true);
  const history = useHistory() || injectedHistory;
  const [tab, setTab] = useState<string>('24');
  const faq = useFaq('premium');
  const isApplying = window.location.href.includes('apply'); // 지원 중 상세보기인지

  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/customer/service/premium/top.jpg').default}
          imageStyle={{
            imageRendering: '-webkit-optimize-contrast',
            filter: 'brightness(0.8)',
          }}
          minHeight={isMobile ? 240 : 380}
          maxHeight={isMobile ? 240 : 380}
        >
          <Space height={isMobile ? 40 : 126} />
          <HorizontalLayout>
            <Space width={isMobile ? 40 : 100} />
            <VerticalLayout>
              <White24 bold style={{ fontSize: isApplying ? '32px' : '28px' }}>
                놀이로 찾는 아이의 재능
                <br />
                <span style={{ color: '#f69F30' }}>창의 놀이</span>
              </White24>
              {!isApplying && (
                <>
                  <Space height={isMobile ? 32 : 52} />
                  <RoundButton
                    variant="primary"
                    style={{ padding: '0px 22px' }}
                    onClick={() => history.push('/service/apply')}
                  >
                    서비스 신청하기
                  </RoundButton>
                </>
              )}
            </VerticalLayout>
          </HorizontalLayout>
        </ImageBox>

        <VerticalLayout
          center
          style={{ width: '100%', background: '#f9f9f9', position: 'relative' }}
        >
          <SVG
            src={require('asset/customer/service/premium/intro.svg').default}
            style={{ maxWidth: '100%', overflow: 'visible' }}
          />
          <img
            src={
              require('asset/customer/service/premium/intro_cards.png').default
            }
            style={{
              imageRendering: '-webkit-optimize-contrast',
              width: isMobile ? 'calc(100% - 40px)' : '400px',
              position: 'absolute',
              bottom: '24px',
              alignSelf: 'center',
            }}
          />
        </VerticalLayout>

        <VerticalLayout center style={{ width: '100%', background: '#f9f9f9' }}>
          <SVG
            src={
              isMobile
                ? require('asset/customer/service/premium/intro3_mobile.svg')
                    .default
                : require('asset/customer/service/premium/intro3.svg').default
            }
            style={{
              maxWidth: isMobile ? '100%' : 'calc(100% - 80px)',
              overflow: 'visible',
            }}
          />
        </VerticalLayout>
        <VerticalLayout center style={{ width: '100%', background: '#fff' }}>
          <SVG
            src={
              require('asset/customer/service/premium/recommendation.svg')
                .default
            }
            style={{ width: '100%', overflow: 'visible' }}
          />
          <Space height={32} />
        </VerticalLayout>

        <Tab
          items={[
            { label: '만 2~4세', value: '24' },
            { label: '만 5~8세', value: '58' },
          ]}
          value={tab}
          onChange={setTab}
        />

        {tab === '24' && (
          <>
            <VerticalLayout
              center
              style={{ width: '100%', background: '#f9f9f9' }}
            >
              <SVG
                src={require('asset/customer/service/premium/24.svg').default}
                style={{ width: '100%', overflow: 'visible' }}
              />
            </VerticalLayout>
          </>
        )}
        {tab === '58' && (
          <>
            <VerticalLayout
              center
              style={{
                width: '100%',
                background: 'white',
                position: 'relative',
              }}
            >
              <Space height={64} />
              <Gray24 center bold>
                창의 놀이로
                <br />
                아이의 재능을 탐색해봐요!
              </Gray24>
              <Space height={32} />

              <img
                src={require('asset/customer/service/premium/58_1.png').default}
                style={{
                  width: '240px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <Space height={69} />

              <HorizontalLayout center>
                <img
                  src={
                    require('asset/customer/service/premium/artist.svg').default
                  }
                  style={{ width: '22px', marginRight: '8px' }}
                />
                <Gray16 semiBold>미래의 피카소 예술가 놀이</Gray16>
              </HorizontalLayout>
              <Space height={16} />
              <Gray12 center>
                다양한 색감 재료로 아이들의 창의성과 자기표현을 도와주는
                놀이에요.
                <br />
                색채 표현법을 배우고 소근육 조작 능력을 발달시키는 예술가 놀이로
                <br />
                우리 아이의 잠재워진 상상력을 깨워 보아요!
              </Gray12>
              <Space height={16} />

              <img
                src={require('asset/customer/service/premium/58_2.png').default}
                style={{
                  width: '240px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <Space height={69} />

              <HorizontalLayout center>
                <img
                  src={
                    require('asset/customer/service/premium/inventor.svg')
                      .default
                  }
                  style={{ width: '22px', marginRight: '8px' }}
                />
                <Gray16 semiBold>미래의 에디슨 발명가 놀이</Gray16>
              </HorizontalLayout>
              <Space height={16} />
              <Gray12 center>
                재활용 재료를 활용하여 발명품을 만드는 놀이에요.
                <br />
                사고의 유연성을 기르고 문제해결능력을 향상시키는 발명가 놀이로
                <br />
                우리 아이의 자기주도성을 발현시켜 보아요!
              </Gray12>
              <Space height={16} />

              <img
                src={require('asset/customer/service/premium/58_3.png').default}
                style={{
                  width: '240px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <Space height={69} />

              <HorizontalLayout center>
                <img
                  src={
                    require('asset/customer/service/premium/builder.svg')
                      .default
                  }
                  style={{ width: '22px', marginRight: '8px' }}
                />
                <Gray16 semiBold>미래의 에디슨 발명가 놀이</Gray16>
              </HorizontalLayout>
              <Space height={16} />
              <Gray12 center>
                점, 선, 면을 활용하여 건축을 경험해 보는 놀이에요.
                <br />
                공간지각 능력을 배우고 집중력을 향상시키는 놀이로
                <br />
                우리 아이의 세상을 바라보는 시각을 넓혀 보아요!
              </Gray12>
              <Space height={16} />

              <img
                src={require('asset/customer/service/premium/58_4.png').default}
                style={{
                  width: '240px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <Space height={69} />

              <HorizontalLayout center>
                <img
                  src={
                    require('asset/customer/service/premium/scientist.svg')
                      .default
                  }
                  style={{ width: '22px', marginRight: '8px' }}
                />
                <Gray16 semiBold>미래의 아이슈타인 과학자 놀이</Gray16>
              </HorizontalLayout>
              <Space height={16} />
              <Gray12 center>
                다양한 재료들을 활용하여 과학 원리를 배우는 놀이에요.
                <br />
                실험을 통해 원리를 직접 추론하는 능력이 키워지는 놀이로
                <br />
                다양한 실험을 통해 우리 아이 탐구력을 향상시켜 보아요!
              </Gray12>
              <Space height={16} />

              <img
                src={require('asset/customer/service/premium/58_5.png').default}
                style={{
                  width: '240px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <Space height={69} />
            </VerticalLayout>
          </>
        )}

        <VerticalLayout
          center
          style={{ width: '100%', background: 'rgba(246, 159, 48, 0.04)' }}
        >
          <SVG
            src={require('asset/customer/service/premium/intro2.svg').default}
            style={{ width: '100%', overflow: 'visible', marginTop: '-16px' }}
          />
        </VerticalLayout>
        <VerticalLayout center style={{ width: '100%', background: '#f9f9f9' }}>
          <SVG
            src={
              isMobile
                ? require('asset/customer/service/premium/kit_mobile.svg')
                    .default
                : require('asset/customer/service/premium/kit.svg').default
            }
            style={{ width: '100%', overflow: 'visible' }}
          />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: 'calc(100% - 80px)', background: 'white' }}
        >
          <Space height={64} />
          <Gray25 bold center>
            창의 놀이
            <br />
            이용시간 안내
          </Gray25>
          <Space height={32} />
          <PremiumServiceMinTable />
          <Space height={12} />
          <LightGray12 right style={{ width: '100%', maxWidth: '400px' }}>
            *최대 주 2회까지만 신청이 가능합니다.
            <br />
            *비정기 돌봄 신청시 이용이 불가합니다.
            <br />
            *창의놀이는 기본돌봄 연계시 이용이 가능합니다.
          </LightGray12>
          <Space height={64} />

          <Gray25 bold center>
            시간당 요금
          </Gray25>
          <Space height={32} />
          <PremiumServiceHourlyPricingTable />
          <Space height={12} />

          <LightGray12
            right
            style={{ width: '100%', maxWidth: '400px', fontSize: '11px' }}
          >
            *공휴일/주말/야간 이용시 2,000원이 추가됩니다. <br />
            *서비스 시작시 최초 1회 필수 준비물 비용 30,000원이
            <br />
            부과됩니다.
          </LightGray12>
          <Space height={32} />

          <RoundButton
            variant="primary"
            style={
              isMobile
                ? {
                    position: 'fixed',
                    width: 'calc(100% - 40px)',
                    bottom: '20px',
                  }
                : {
                    width: '100%',
                    borderRadius: '0px',
                    position: 'absolute',
                    bottom: '0px',
                  }
            }
            onClick={() => {
              if (onClickApply) onClickApply();
              else if (sheet) onClose?.();
              else history.push('/service/apply');
            }}
          >
            서비스 신청하기
          </RoundButton>
          <Space height={52} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: '#f9f9f9', padding: '0px 40px' }}
        >
          <Space height={64} />
          <Gray24 bold center>
            Q & A
          </Gray24>
          <Space height={32} />
          <FaqContainer>
            {faq.map((x, idx) => (
              <FaqAccordianItem key={idx} data={x} />
            ))}
          </FaqContainer>
          <Space height={32} />
          <RoundButton
            variant="primary"
            style={
              isMobile
                ? {
                    position: 'fixed',
                    width: 'calc(100% - 40px)',
                    bottom: '20px',
                  }
                : {
                    width: '100%',
                    borderRadius: '0px',
                    position: 'absolute',
                    bottom: '0px',
                  }
            }
            onClick={() => {
              if (onClickApply) onClickApply();
              else if (sheet) onClose?.();
              else history.push('/service/apply');
            }}
          >
            {isApplying ? '확인' : '서비스 신청하기'}
          </RoundButton>
          <Space height={32} />
        </VerticalLayout>
      </Container>
    </>
  );
};

const Container = styled(VerticalLayout)`
  width: 100%;
  position: static;

  background: white;

  overflow-x: hidden;
`;

const FaqContainer = styled.div`
  width: 100%;

  background: #f9f9f9;

  padding: 34px 18px;

  .MuiPaper-root.MuiAccordion-root {
    padding: 5px 5px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 32px !important;
  }
  .MuiAccordionSummary-root {
    font-size: 15px !important;
  }
  .MuiAccordionDetails-root {
    font-size: 14px !important;
  }
  .MuiAccordionSummary-expandIcon {
    zoom: 0.5;
  }
`;
