import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { Space, Push, VerticalLayout, HorizontalLayout } from 'atom/layout';
import { AdditionalServiceCard, DefaultServiceCard } from 'atom/care';
import { useStores } from 'state';
import { IntroPremiumPage } from 'page-mobile/customer/intro/IntroPremiumPage';
import { CareType, isAddonCareType } from 'model';
import { Overlay } from 'atom/display';
import { MobileContextProvider, useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestStep4 } from './RequestStep4';
import { RequestStep5Irregular } from './RequestStep5Irregular';
import { ServiceSelectionPopup } from 'component/popup';
import { IntroExtraPage } from 'page-mobile/customer/intro/IntroExtraPage';

export const RequestStepAdditionalServices = observer(({ Bottom, onNext }) => {
  const topmost = useTopmost();
  const { customerStore } = useStores();

  const onClickNext = () => {
    if (customerStore.form.careKind === '정기') {
      onNext(RequestStep4);
    } else {
      onNext(RequestStep5Irregular);
    }
  };
  const flush = () => {
    customerStore.form.careTypes = customerStore.form.careTypes.filter(
      x => !isAddonCareType(x),
    );
  };
  const onChangePremium = (x: boolean) => {
    customerStore.form.premium = x;
    customerStore.form.cleaning = false;
    flush();

    if (x) {
      customerStore.form.careTypes = [
        ...customerStore.form.careTypes,
        CareType.Premium,
      ];
    } else {
      customerStore.form.careTypes = customerStore.form.careTypes.filter(
        x => x !== CareType.Premium,
      );
    }
  };
  const onChangeCleaning = (x: boolean) => {
    customerStore.form.cleaning = x;
    customerStore.form.premium = false;
    flush();

    if (x) onShowExtraServicesPopup();
    else {
      customerStore.form.careTypes = customerStore.form.careTypes.filter(
        x => x !== CareType.Cleaning && x !== CareType.Cooking,
      );
    }
  };

  const onClickPremiumService = () => {
    const id = topmost.show(
      <Overlay style={{ width: '660px' }} onClose={() => topmost.dismiss(id)}>
        <IntroPremiumPage sheet onClose={() => topmost.dismiss(id)} />
      </Overlay>,
    );
  };
  const onClickExtraServices = () => {
    const id = topmost.show(
      <Overlay style={{ width: '660px' }} onClose={() => topmost.dismiss(id)}>
        <IntroExtraPage sheet onClose={() => topmost.dismiss(id)} />
      </Overlay>,
    );
  };
  const onShowExtraServicesPopup = () => {
    const id = topmost.show(
      <ServiceSelectionPopup onClose={() => topmost.dismiss(id)} />,
    );
  };

  useEffect(() => {
    if (!customerStore.form.cleaningDetail) return;

    flush();

    if (customerStore.form.cleaningDetail === 'cooking') {
      customerStore.form.careTypes = [
        ...customerStore.form.careTypes,
        CareType.Cooking,
      ];
    } else {
      customerStore.form.careTypes = [
        ...customerStore.form.careTypes,
        CareType.Cleaning,
      ];
    }
  }, [customerStore.form.cleaningDetail]);

  return (
    <>
      <Title>
        원하시는 추가 서비스가 있다면
        <br />
        추가해주세요
      </Title>
      <Space height={8} />
      <Subtitle>
        *추가 서비스는 선택 사항입니다.
        <br />
        *추가할 서비스가 없다면 다음 버튼을 눌러주세요.
      </Subtitle>
      <Space height={53} />

      <DefaultServiceCard
        title="기본 놀이 돌봄"
        price="시간당 13,000원~"
        body="등하원, 실내/야외 놀이, 책 읽기, 목욕, 식사 등"
        comment="등원 최소 2시간, 하원/놀이돌봄만 최소 3시간"
      />
      <Space height={8} />

      <img
        src={require('asset/icon/plus.svg').default}
        style={{ width: '17px', marginLeft: 'calc(50% - 8px)' }}
      />
      <Space height={8} />

      <AdditionalServiceCard
        serviceType="premium"
        value={customerStore.form.premium}
        onChange={onChangePremium}
        onDetail={onClickPremiumService}
      />
      <Space height={16} />
      <AdditionalServiceCard
        serviceType="cleaning"
        value={customerStore.form.cleaning}
        onChange={onChangeCleaning}
        onDetail={onClickExtraServices}
      />

      <Space height={30} />
      <Bottom progress={(1 / 11) * 1} onClickNext={onClickNext} />
    </>
  );
});
