export enum Personality {

};

// 필요한 놀이
export const DesiredPlays = [
  { label: '자기 이해를 도울 수 있는 놀이', value: 0 },
  { label: '창의성을 도모할 수 있는 놀이', value: 1 },
  { label: '언어 발달을 위한 놀이', value: 2 },
  { label: '자유로운 예술표현 놀이', value: 3 },
  { label: '활발하고 에너지 있는 신체놀이', value: 4 },
  { label: '호기심을 해소할 수 있는 탐구놀이', value: 5 },
  { label: '풍부한 감성을 표현할 수 있는 놀이', value: 6 },
  { label: '바른 인성과 기본생활습관을 익히는 놀이', value: 7 },
];

// 좋아하는 놀이
export const FavoritePlays = [
  { label: '쌓기놀이', value: 0 },
  { label: '오감놀이', value: 1 },
  { label: '미술놀이', value: 2 },
  { label: '바깥놀이', value: 3 },
  { label: '탐구놀이', value: 4 },
  { label: '신체놀이', value: 5 },
  { label: '춤/노래', value: 6 },
  { label: '보드게임', value: 7 },
  { label: '퀴즈풀기', value: 8 },
  { label: '대화하기', value: 9 },
];

// 성향
export const Propensities = [
  { label: '자기 주장이 뚜렷해요', value: 0 },
  { label: '자기 이해가 높아요', value: 1 },
  { label: '예술적 표현을 즐겨요', value: 2 },
  { label: '활발하고 에너지가 많아요', value: 3 },
  { label: '호기심이 많고 긍정적이에요', value: 4 },
  { label: '새로운것을 잘 받아들여요', value: 5 },
  { label: '차분하고 세심한 편이에요', value: 6 },
  { label: '기발한 생각을 잘 표현해요', value: 7 },
  { label: '하고 싶은 놀이와 이야기가 많아요', value: 8 },
];
