import React, { useState } from 'react';
import styled from 'styled-components';

import { VerticalLayout, HorizontalLayout, Space, Push } from 'atom/layout';
import { Color, URL } from 'constant';
import { CompanyInfo } from 'content';

export const Footer = ({

}) => {
  return (
    <Container>
      <Push maxWidth={300} />
      
      <VerticalLayout>
        <Logo />
        <Space height={26} />
        <DescriptionText>
          (주)우리동네히어로 <br/>
          주소 : 서울특별시 성동구 성수일로12길 20, 4층 우리동네히어로  <br/>
          사업자 등록번호 : 155-88-01006   |   대표자 : 손민희<br/>
          고객센터 문의 : 02-6232-0206  / sitterhero@naver.com <br/>
          (평일 오전 10시~오후 6시, 주말 및 공휴일 제외)<br/>
          {CompanyInfo.Copyright}
        </DescriptionText>
      </VerticalLayout>
      <Push />
      <VerticalLayout>
        <MenuItemText>
          개인정보처리방침
        </MenuItemText>
        <Space height={10} />
        <MenuItemText>
          서비스 이용약관
        </MenuItemText>
        <Space height={60} />

        <HorizontalLayout>
          <SocialButton
            src={require('asset/home/kakao.png').default}
            onClick={() => window.open(URL.Kakao)}
          />
          <Space width={20} />
          <SocialButton
            src={require('asset/home/blog.png').default}
            onClick={() => window.open(URL.Blog)}
          />
          <Space width={20} />
          <SocialButton
            src={require('asset/home/insta.png').default}
            onClick={() => window.open(URL.Instagram)}
          />
        </HorizontalLayout>
      </VerticalLayout>
      <Push maxWidth={270} />
    </Container>
  )
};

const Container = styled.footer`
  width: 100%;
  height: 364px;
  display: flex;
  flex-direction: row;

  align-items: center;

  background: #2b2b2b;
`;
const Logo = styled.img.attrs({
  src: require('asset/logo-dark.png').default,
})`
  width: 131px;
  height: 61px;
`;
const SocialButton = styled.img`
  width: 53px;
  height: 53px;

  cursor: pointer;

  transition: all 0.2s ease;

  &:hover {
    transform: scale(0.9);
  }
`;
const DescriptionText = styled.div`
  color: white;

  font-size: 18px;
  font-weight: 300;
`;
const MenuItemText = styled.div`
  color: white;

  font-size: 18px;
  font-weight: 300;
  text-align: right;

  cursor: pointer;
`;
