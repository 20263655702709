import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { UnderlinedText } from 'atom/text';
import { ChildPopup } from 'component/popup';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestStep11 } from './RequestStep11';

export const RequestStep10 = observer(({
  Bottom,
  onNext,
}) => { 
  const { customerStore } = useStores();

  const onSelect = (value: string) => {
    if (value === 'x')
      customerStore.form.pets = [];
  };

  useEffect(() => {
    if (!customerStore.form.pets) return;

    if (customerStore.form.pets.length === 0)
      customerStore.form.pet = '없어요';
    else
      customerStore.form.pet = customerStore.form.pets.filter(x => x !== 'x').join(',');
  }, [customerStore.form.pets]);

  return (
    <>
      <Title>
        반려동물이 있나요?
      </Title>
      <Space height={8} />
      <Subtitle>
        *중복으로 선택이 가능합니다.
      </Subtitle>
      <Space height={60} />

      <MultiSelect
        twoColumns
        items={[
          { label: '강아지', value: '강아지' },
          { label: '고양이', value: '고양이' },
          { label: '기타', value: '기타' },
          { label: '없어요', value: 'x' },
        ]}
        value={customerStore.form.pets.length === 0 ? ['x'] : customerStore.form.pets.filter(x => x !== 'x')}
        onSelect={onSelect}
        onChange={e => customerStore.form.pets = e}
      />
      <Space height={60} />
      <Bottom
        progress={1 / 11 * 10}
        onClickNext={() => onNext(RequestStep11)}
      />
    </>
  );
});
