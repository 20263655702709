import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { RoundButton } from 'atom/button';
import { Push, Space, HorizontalLayout, VerticalLayout } from 'atom/layout';
import { useTopmost } from 'util/index';
import { URL } from 'constant';

export const RequestDone = observer(({

}) => {
  const history = useHistory();

  return (
    <VerticalLayout center>
      <DoneImage
        className="animate__animated animate__zoomIn"
      />
      <Space height={23} />

      <DoneText
        className="animate__animated animate__fadeIn"
      >
        신청이 완료 되었습니다!<br />
        영업일 기준 1~2일 내에 연락 드리겠습니다.
      </DoneText>
      <Space height={52} />

      <InfoBox
        className="animate__animated animate__fadeIn"
      >
        <Space height={26} />
        서비스 신청내역을 확인하시거나 수정하시려면<br/>
        우리동네 돌봄히어로 앱을 다운받으세요!
        <Space height={28} />
        <HorizontalLayout>
          <Push />
          <ImageButton
            src={require('asset/common/android.png').default}
            onClick={() => showConfirm('COMING SOON!\n우리동네 돌봄히어로의 앱이\n곧 출시됩니다.')}
            //onClick={() => window.open(URL.AndroidApp)}
          />
          <Space width={12} />
          <ImageButton
            src={require('asset/common/ios.png').default}
            onClick={() => showConfirm('COMING SOON!\n우리동네 돌봄히어로의 앱이\n곧 출시됩니다.')}
            //onClick={() => window.open(URL.AndroidApp)}
          />
          <Push />
        </HorizontalLayout>        
      </InfoBox>
      <Space height={32} />
      
      <RoundButton
        style={{ width: '100%' }}
        onClick={() => history.push('/')}
      >
        흠으로 가기
      </RoundButton>
    </VerticalLayout>
  );
});

const DoneImage = styled.img.attrs({
  src: require('asset/done.png').default,
})`
  width: 263px;
  height: 233px;
`;
const DoneText = styled.div`
  color: #484848;

  font-size: 24px;
  font-weight: bold;

  letter-spacing: -1.2px;
  text-align: center;

  --animate-delay: 0.5s;
`;
const InfoBox = styled.div`
  width: 100%;
  height: 164px;

  color: #484848;

  border-radius: 10px;
  background-color: #f7f7f7;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1.63;
  letter-spacing: -0.8px;

  --animate-delay: 0.9s;
`;
const ImageButton = styled.img`
  width: 112px;

  cursor: pointer;
`;
