import React from 'react';
import styled from 'styled-components';

import {HorizontalLayout, Push, Space} from 'atom/layout';
import { SitterReviewContent } from 'content';
import { Color } from 'constant';
import {Black17} from "atom/text";

interface ReviewCardProps {
  data: SitterReviewContent;
  onClick?: () => void;
};
export const ReviewCard = ({
  data,
  onClick,
}: ReviewCardProps) => {
  return (
    <Container
      className={'review-card'}
      onClick={onClick}
    >
      <ImageWrap>
        <Image
          src={data.thumbnail}
        />
      </ImageWrap>
      <IntroTextWrap>
        <div>
          <img src={require('asset/mobile/home/star5.png').default} />
          <Space height={5} />
          <Title>{data.title}</Title>
          <Space height={5} />
          {data.content}
        </div>
        <HorizontalLayout center style={{justifyContent: 'space-between'}}>
        <NameText>
          {data.author}
        </NameText>
          <div onClick={() => window.open(data.url, '_blank')}>
            <img style={{width:48}} src={require('asset/mobile/home/review_more_btn.png').default}/>
          </div>
        </HorizontalLayout>
      </IntroTextWrap>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 252px;
  height: 322px;
  overflow: hidden;

  background: white;
  border-radius: 10px;
  box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.16);

  margin: 7px;
`;
const ImageWrap = styled.div`
  width: 100%;
  height: 148px;
  
  background-color: #FFEDD5;
  
  overflow: hidden;
`
const Image = styled.img`
  width: 100%;
  height: 148px;

  object-fit: cover;
`;
const IntroTextWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  padding: 8px;

  font-size: 10px;
  font-weight: 300;

  line-height: 18px;
  white-space: pre-line;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  
  letter-spacing: -0.65px;
`
const NameText = styled.div`
  font-size: 12px;
  font-weight: 700;

  line-height: 22px;
  letter-spacing: -0.6px;
`;
