import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space, Push } from 'atom/layout';
import { useStores } from 'state';
import { CareKind, CareType } from 'model';
import { Title, Subtitle } from './style';
import { RequestStepAdditionalServices, RequestStep5Irregular } from '.';

export const RequestStep2 = observer(({
  Bottom,
  onNext,
}) => {
  const { customerStore } = useStores();

  const onClickNext = () => {
    if (customerStore.form.careKind === CareKind.Once)
      onNext(RequestStep5Irregular);
    else
      onNext(RequestStepAdditionalServices);
  };

  useEffect(() => {
    if (!customerStore.form.careTypes) return;

    customerStore.form.careTypes = customerStore.form.careTypes.sort((a, b) => {
      return Order[a] > Order[b] ? 1 : -1;
    });
  }, [customerStore.form.careTypes]);

  return (
    <>
      <Title>
        돌봄 종류를<br />
        선택해주세요.
      </Title>
      <Space height={8} />
      <Subtitle>
        *중복으로 선택이 가능합니다.
      </Subtitle>
      <Space height={29} />

      <MultiSelect
        items={[
          { label: '등원 + 놀이돌봄', value: CareType.DropOff },
          { label: '하원 + 놀이돌봄', value: CareType.PickUp },
          { label: '놀이돌봄만', value: CareType.PlayOnly },
        ]}
        value={customerStore.form.careTypes}
        onChange={e => customerStore.form.careTypes = e}
      />
      <Space height={30} />
      <Bottom
        progress={1 / 11 * 2}
        disabled={customerStore.form.careTypes.length === 0}
        onClickNext={onClickNext}
      />
    </>
  );
});

const Order = {
  [CareType.DropOff]: 1,
  [CareType.PickUp]: 2,
  [CareType.PlayOnly]: 3,
};
