import styled from 'styled-components';

import { Color } from 'constant';
export * from './HighlightedText';

export const UnderlinedText = styled.div`
  color: #484848;

  font-size: 17px;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;

  letter-spacing: -0.85px;

  cursor: pointer;
`;

interface FontProps {
  bold?: boolean;
  semiBold?: boolean;
  medium?: boolean;
  light?: boolean;
  center?: boolean;
  right?: boolean;
};
const Font = styled.div<FontProps>`
  white-space: pre-line;

  ${({ bold = false }) => bold ? `
    font-weight: bold !important;
  ` : `
  `}
  ${({ semiBold = false }) => semiBold ? `
    font-weight: 500 !important;
  ` : `
  `}
  ${({ medium = false }) => medium ? `
    font-weight: 500;
  ` : `
  `}
  ${({ light = false }) => light ? `
    font-weight: 300;
  ` : `
  `}
  ${({ center = false }) => center ? `
    text-align: center;
  ` : `
  `}
  ${({ right = false }) => right ? `
    text-align: end;
  ` : `
  `}
`;

const Primary = styled(Font)`
  color: ${Color.Primary};
`;
export const Primary16 = styled(Primary)`
  font-size: 16px;

  letter-spacing: -0.8px;
`;
export const Primary18 = styled(Primary)`
  font-size: 18px;
`;
export const Primary24 = styled(Primary)`
  font-size: 24px;
`;
export const Primary34 = styled(Primary)`
  font-size: 34px;
`;
export const Primary48 = styled(Primary)`
  font-size: 48px;

  letter-spacing: -0.8px;
`;

const White = styled(Font)`
  color: white;
`;
export const White12 = styled(White)`
  font-size: 12px;
  font-weight: 500;

  line-height: 1.92;
  letter-spacing: -0.54px;
`;
export const White16 = styled(White)`
  font-size: 16px;
`;
export const White18 = styled(White)`
  font-size: 18px;
  font-weight: 300;

  line-height: 2;
  letter-spacing: -0.9px;
`;
export const White21 = styled(White)`
  font-size: 21px;  

  line-height: 1.44;
  letter-spacing: -1.05px;
`;
export const White24 = styled(White)`
  font-size: 24px;
  font-weight: 300;

  line-height: 1.44;
  letter-spacing: -1.25px;
`;
export const White25 = styled(White)`
  font-size: 25px;  
  font-weight: 300;

  line-height: 1.44;
  letter-spacing: -1.25px;
`;
export const White40 = styled(White)`
  font-size: 40px;

  line-height: 1.38;
  letter-spacing: -2px;
`;
export const White44 = styled(White)`
  font-size: 44px;
  font-weight: bold;

  line-height: 0.82;
  letter-spacing: -2.2px;
`;

const Black = styled(Font)`
  color: black;
`;
export const Black14 = styled(Black)`
  font-size: 14px;

  letter-spacing: -0.35px;
`;
export const Black15 = styled(Black)`
  font-size: 15px;
  font-weight: 300;

  line-height: 1.4;
`;
export const Black16 = styled(Black)`
  font-size: 16px;

  letter-spacing: -0.8px;
`;
export const Black17 = styled(Black)`
  font-size: 17px;
`;
export const Black18 = styled(Black)`
  font-size: 18px;
`;
export const Black19 = styled(Black)`
  font-size: 19px;
  
  line-height: 1.47;
  letter-spacing: -0.38px;
`;
export const Black20 = styled(Black)`
  font-size: 20px;
  
  line-height: 1.5;
  letter-spacing: normal;
`;
export const Black23 = styled(Black)`
  font-size: 23px;
  
  line-height: 1.48;
  letter-spacing: -1.15px;
`;
export const Black24 = styled(Black)`
  font-size: 24px;
`;
export const Black25 = styled(Black)`
  font-size: 25px;

  line-height: 1.44;
  letter-spacing: -1.25px;
`;
export const Black28 = styled(Black)`
  font-size: 28px;
  
  line-height: 1.18;
  letter-spacing: normal;
`;
export const Black40 = styled(Black)`
  font-size: 40px;

  line-height: 1.38;
  letter-spacing: -2px;
`;
export const Black48 = styled(Black)`
  font-size: 48px;

  line-height: 1.54;
  letter-spacing: -2.4px;
`;

const Gray = styled(Font)`
  color: #484848;
`;
export const Gray9 = styled(Gray)`
  font-size: 9px;
  
  letter-spacing: -0.45px;
`;
export const Gray10 = styled(Gray)`
  font-size: 10px;
`;
export const Gray12 = styled(Gray)`
  font-size: 12px;

  letter-spacing: -0.48px;
`;
export const Gray13 = styled(Gray)`
  font-size: 13px;

  letter-spacing: -0.48px;
`;
export const Gray14 = styled(Gray)`
  font-size: 14px;
`;
export const Gray16 = styled(Gray)`
  font-size: 16px;

  letter-spacing: -0.8px;
`;
export const Gray18 = styled(Gray)`
  font-size: 18px;

  line-height: 1.5;
  letter-spacing: -0.72px;
`;
export const Gray20 = styled(Gray)`
  font-size: 20px;

  line-height: 1.7;
  letter-spacing: -1px;
`;
export const Gray24 = styled(Gray)`
  font-size: 24px;
`;
export const Gray25 = styled(Gray)`
  font-size: 25px;

  letter-spacing: -1.25px;
`;
export const Gray34 = styled(Gray)`
  font-size: 34px;
`;
export const Gray40 = styled(Gray)`
  font-size: 40px;
`;

const LightGray = styled(Font)`
  color: #969696;
`;
export const LightGray10 = styled(LightGray)`
  font-size: 10px;
`;
export const LightGray12 = styled(LightGray)`
  font-size: 12px;
`;
export const LightGray14 = styled(LightGray)`
  font-size: 14px;

  line-height: 1.67;
`;
export const LightGray15 = styled(LightGray)`
  font-size: 15px;

  line-height: 1.67;
`;
export const LightGray16 = styled(LightGray)`
  font-size: 16px;

  letter-spacing: -0.8px;
`;
export const LightGray20 = styled(LightGray)`
  font-size: 20px;

  line-height: 1.7;
  letter-spacing: -1px;
`;
export const LightGray28 = styled(LightGray)`
  font-size: 28px;

  line-height: 1.7;
  letter-spacing: -1px;
`;
