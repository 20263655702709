import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem as MuiMenuItem } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import { HorizontalLayout, Space, Push } from 'atom/layout';
import { Color } from 'constant';
import { MenuItem, MenuItemText } from './MenuItem';

export const Header = ({

}) => {
  const history = useHistory();

  return (
    <SHeader>
      <Container>
        <Push maxWidth={300} />
        <Logo
          onClick={() => history.replace('/')}
        />
        <Space width={95} />
        <MenuItem
          label="돌봄서비스"
        >
          <Link aria-label="돌봄서비스 소개" to="/service/intro">
            <MuiMenuItem>
              돌봄서비스 소개
            </MuiMenuItem>
          </Link>
          <Link aria-label="돌봄서비스 신청" to="/service/apply">
            <MuiMenuItem>
              돌봄서비스 신청
            </MuiMenuItem>
          </Link>
        </MenuItem>
        <Space width={75} />
        <MenuItem
          label="히어로 선생님"
        >
          <Link aria-label="히어로선생님 혜택" to="/hero/about">
            <MuiMenuItem>
              히어로선생님 혜택
            </MuiMenuItem>
          </Link>
          <Link aria-label="히어로선생님 지원" to="/hero/apply">
            <MuiMenuItem>
              히어로선생님 지원
            </MuiMenuItem>
          </Link>
        </MenuItem>
        <Push />
        <Link aria-label="자주묻는 질문" to="/faq">
          <MenuItemText>
            자주묻는 질문
          </MenuItemText>
        </Link>
        <Space width={75} />
        <Link aria-label="이용요금" to="/service/pricing">
          <MenuItemText>
            이용요금
          </MenuItemText>
        </Link>
        <Push maxWidth={300} />
      </Container>
    </SHeader>
  )
};

const SHeader = styled.header`
`;
const Container = styled.nav`
  position: relative;
  display: flex;

  width: 100%;
  height: 100px;

  align-items: center;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  z-index: 100;
`;
const Logo = styled.img.attrs({
  src: require('asset/logo.png').default,
})`
  width: 131px;
  height: 61px;

  cursor: pointer;

  transition: all 0.52s ease;

  &:hover {
    transform: scale(0.95);
  }
`;
