import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { uniq } from 'lodash';

import { Space, Push } from 'atom/layout';
import { ServiceCard } from 'atom/care';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep6 } from './RequestStep6';

export const RequestStepHeroType = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();

  const _onNext = () => {
    if (customerStore.form.heroTypes.length < 3)
      showConfirm(
        `원하시는 선생님이 계시지 않을 경우, \n경력 상관 없이 활동가능한 선생님을 추천해드립니다.`,
      );
    onNext(RequestStep6);
  };

  return (
    <>
      <Title>원하시는 선생님이 있나요?</Title>
      <Space height={8} />
      <Subtitle>
        *중복 선택이 가능합니다.
        <br />
        *선생님에 따라 돌봄료가 달라질 수 있습니다.
      </Subtitle>
      <Space height={53} />

      <ServiceCard
        title="일반 선생님"
        price="시간당 13,000원"
        body="히어로만의 까다로운 7단계 인증과정을 통과한 믿을 수 있는 선생님이에요"
        value={customerStore.form.heroTypes.includes('일반')}
        onChange={e => {
          if (e)
            customerStore.form.heroTypes = uniq([
              ...customerStore.form.heroTypes,
              '일반',
            ]);
          else
            customerStore.form.heroTypes = customerStore.form.heroTypes.filter(
              x => x !== '일반',
            );
        }}
      />
      <Space height={27} />

      <ServiceCard
        title="경력 선생님"
        price="시간당 14,000원"
        body="히어로에서 500시간 혹은 6개월 이상 활동한 경력 선생님들을 원해요"
        value={customerStore.form.heroTypes.includes('경력')}
        onChange={e => {
          if (e)
            customerStore.form.heroTypes = uniq([
              ...customerStore.form.heroTypes,
              '경력',
            ]);
          else
            customerStore.form.heroTypes = customerStore.form.heroTypes.filter(
              x => x !== '경력',
            );
        }}
      />
      <Space height={27} />
      <ServiceCard
        title="프로 선생님"
        price="시간당 15,000원"
        body="히어로에서 1000시간 혹은 1년 이상 활동한 프로 선생님들을 원해요 "
        value={customerStore.form.heroTypes.includes('프로')}
        onChange={e => {
          if (e)
            customerStore.form.heroTypes = uniq([
              ...customerStore.form.heroTypes,
              '프로',
            ]);
          else
            customerStore.form.heroTypes = customerStore.form.heroTypes.filter(
              x => x !== '프로',
            );
        }}
      />

      <Space height={30} />
      <Bottom
        disabled={customerStore.form.heroTypes.length === 0}
        progress={(1 / 11) * 5}
        onClickNext={_onNext}
      />
    </>
  );
});
