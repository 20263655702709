import { ITerms } from 'model';

// 돌봄서비스 등록 시 약관
export const CustomerRequestTerms = [
  {
    type: 'accept',
    name: '서비스 이용약관',
    required: true,
    url: '',
  },
  {
    type: 'privacyPolicy',
    name: '개인정보 수집 및 이용동의',
    required: true,
    url: '',
  },
  {
    type: 'accept',
    name: '광고정보 수신동의',
    required: false,
    url: '',
  },
] as ITerms[];

// 시터 지원하기 시 약관
export const SitterRegisterTerms = [
  {
    type: 'accept',
    name: '서비스 이용약관',
    required: true,
    url: '',
  },
  {
    type: 'privacyPolicy',
    name: '개인정보 수집 및 이용동의',
    required: true,
    url: '',
  },
  {
    type: 'accept',
    name: '광고정보 수신동의',
    required: false,
    url: '',
  },
] as ITerms[];
