export interface ISitterIntroContent {
  name: string;
  content: string;
  image: string;
}

export const SitterIntroContents = [
  {
    name: '8기 유경희 선생님',
    content:
      '“여유시간을 활용해서 예쁜 아이들도 만나고 경력도 쌓으니 일석이조에요.”',
    image: require('asset/sitter/portrait/1.png').default,
  },
  {
    name: '11기 신영숙 선생님',
    content:
      '“아이돌봄 일이 처음이라 조금은 망설였는데 센터에서 잘 관리해주셔서 즐겁게 일하고 있어요!”',
    image: require('asset/sitter/portrait/2.png').default,
  },
  {
    name: '7기 이미희 선생님',
    content:
      '“평생 주부로 살았던 저에게 히어로 선생님은 직업이자 자부심이에요.”',
    image: require('asset/sitter/portrait/3.png').default,
  },
  {
    name: '49기 이영수 선생님',
    content:
      '“34년 동안 선생님으로 일한 저에게 히어로는 사회의 첫걸음을 시작하게 해준 안내자에요.”',
    image: require('asset/sitter/portrait/4.png').default,
  },
  {
    name: '16기 이충자 선생님',
    content:
      '“내 아이를 양육해본 사람은 척척 잘 해낼 수 있잖아요. 육아 경험을 살려 많은 분들이 도전해줬으면 좋겠어요.”',
    image: require('asset/sitter/portrait/5.png').default,
  },
] as ISitterIntroContent[];
