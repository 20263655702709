import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { isNil } from 'lodash';

import { HorizontalLayout, VerticalLayout, Space } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { Paper } from 'atom/deco';
import { Progress } from 'atom/progress';
import { Header } from 'component/header';
import { Footer } from 'component/footer';
import { useStores } from 'state';
import { usePrices } from 'state/hook/value';
import {
  RegisterStep1,
} from './register';
import {
  RequestFaq,
  RequestStep1, RequestStep2, RequestStep3, RequestStep4, RequestStep5Regular,
  RequestStep6, RequestStep7, RequestStep8,
  RequestPersonalityCheck1, RequestPersonalityCheck2, RequestPersonalityCheck3, RequestPersonalityCheck4,
  RequestConfirm,
  RequestDone,
  RequestAlreadyDone,
  RequestChildInfo1,
} from './request';

export const CustomerRequestPage = ({

}) => {
  const history = useHistory();
  const { customerStore } = useStores();
  const [stepHistory, setStepHistory] = useState<React.FC<any>[]>([]);
  const [FormComponent, setFormComponent] = useState<React.FC<any>>();
  const prices = usePrices();

  const onClickPrev = () => {
    if (stepHistory.length === 1) return;
    stepHistory.pop();
    setStepHistory([...stepHistory]);
    setFormComponent(stepHistory[stepHistory.length - 1]);
  };
  const onLogin = async () => {
    try {
      await customerStore.refreshMe();
      if (customerStore.isLoggedIn) {
        const previousCares = await customerStore.getCares();
        if (previousCares.length > 0) {
          setFormComponent(RequestAlreadyDone);
        } else
          setFormComponent(RequestStep1);
      } else
        setFormComponent(RegisterStep1);
    } catch {
      setFormComponent(RegisterStep1);
    }
  };

  useEffect(() => {
    customerStore.resetForm();
    onLogin();
  }, []);
  useEffect(() => {
    if (!FormComponent) return;

    if (stepHistory.includes(FormComponent) === false)
      setStepHistory([...stepHistory, FormComponent]);

    if (FormComponent === RequestStep8) {
      setStepHistory(stepHistory => stepHistory.filter(x => ![
        RequestChildInfo1,
        RequestPersonalityCheck1,
        RequestPersonalityCheck2,
        RequestPersonalityCheck3,
        RequestPersonalityCheck4,
      ].includes(x))); 
    }

    window.history.replaceState(
      { },
      '돌봄 서비스 신청하기',
      FormComponent === RequestConfirm
        ? `/service/apply/done`
        : `/service/apply/q${stepHistory.length + 1}`,
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [FormComponent]);

  return (
    <>
      <Header />
      <Container>
        <Paper
          style={{ paddingTop: '30px' }}
        >
          <HorizontalLayout>
            <Logo />
            <Space width={15} />
            <Title>
              돌봄 서비스 신청하기
            </Title>
          </HorizontalLayout>
          <Space height={31} />
          <hr />

          {!!FormComponent && (
            <FormContainer>
              <FormComponent
                Bottom={(props) => (
                  <FormBottom
                    progress={10}
                    disabled={false}
                    onClickPrev={onClickPrev}
                    {...props}
                  />
                )}
                onLogin={onLogin}
                onNext={setFormComponent}
              />
            </FormContainer>
          )}
          {!FormComponent && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

interface FormBottomProps {
  progress: number;
  disabled: boolean;
  nextLabel?: string;
  onClickPrev: () => void;
  onClickNext: () => void;
};
const FormBottom = ({
  progress,
  disabled = false,
  nextLabel,
  onClickPrev,
  onClickNext,
}: FormBottomProps) => {
  return (
    <>
      {!isNil(progress) && (
        <>
          <Progress
            value={progress * 100}
          />
          <Space height={40} />
        </>
      )}

      <HorizontalLayout>
        {!!onClickPrev && (
          <>
            <RoundButton
              variant="white"
              style={{ width: '130px', padding: '0px' }}
              onClick={onClickPrev}
            >
              이전
            </RoundButton>
            <Space width={10} />
          </>
        )}
        <RoundButton
          fill
          disabled={disabled}
          onClick={onClickNext}
        >
          {nextLabel || '다음'}
        </RoundButton>
      </HorizontalLayout>
    </>
  );
};

const Container = styled.div`
  display: flex;

  background-color: #f7f7f7;

  justify-content: center;

  padding-top: 30px;
  padding-bottom: 200px;
`;
const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 300px;

  align-items: center;
  justify-content: center;
`;
const Logo = styled.img.attrs({
  src: require('asset/logo-small.svg').default,
})`
  width: 42px;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 500;

  letter-spacing: -1.2px;
`;
const FormContainer = styled.div`
  padding: 50px 80px 10px 80px;
`;
