import React from 'react';
import styled from 'styled-components';

import { Color } from 'constant';

interface SelectProps {
  twoColumns?: boolean;
  gap?: number;
  items: LabelValue[];
  value: any | any[];
  onChange: (x: any) => void;
};
export const Select = ({
  twoColumns = false,
  gap = 20,
  items,
  value,
  onChange,
}: SelectProps) => {
  return (
    <Container
      twoColumns={twoColumns}
      gap={gap}
    >
      {items.map(x => (
        <Item
          key={x.value}
          twoColumns={twoColumns}
          active={value === x.value}
          onClick={() => onChange(x.value)}
        >
          {x.label} 
        </Item>
      ))}
    </Container>
  );
};

interface MultiSelectProps {
  twoColumns?: boolean;
  gap?: number,
  items: LabelValue[];
  value: any[];
  onSelect?: (x: any) => void;
  onChange: (x: any[]) => void;
};
export const MultiSelect = ({
  twoColumns = false,
  gap = 20,
  items,
  value,
  onSelect = () => {},
  onChange,
}: MultiSelectProps) => {
  return (
    <Container
      twoColumns={twoColumns}
      gap={gap}
    >
      {items.map(x => (
        <Item
          key={x.value}
          twoColumns={twoColumns}
          active={value.includes(x.value)}
          onClick={() => {
            if (value.includes(x.value))
              onChange(value.filter(y => y !== x.value));
            else {
              onChange([...value, x.value]);
              onSelect(x.value);
            }
          }}
        >
          {x.label} 
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;

  user-select: none;

  ${({ gap }) => `
    gap: ${gap}px;
  `}
  ${({ twoColumns }) => twoColumns ? `
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
  ` : `
  `}
`;
const Item = styled.div<any>`
  display: flex;
  width: 100%;
  height: 60px;

  font-size: 17px;
  font-weight: 500;

  cursor: pointer;

  border-radius: 50px !important;

  align-items: center;
  justify-content: center;

  transition: all 0.15s ease;

  ${({ active }) => active ? `
    color: ${Color.Primary};
    background-color: #ffedd5;
  ` : `
    color: #c0c0c0;
    border: solid 1px #b7b7b7;
  `}
  ${({ twoColumns }) => twoColumns ? `
    width: calc(50% - 5px);

    border-radius: 25px;
  ` : `
  `}
`;
