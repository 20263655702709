import React from 'react';
import styled from 'styled-components';

import { Space, HorizontalLayout } from 'atom/layout';

export const CheckboxVariant = {
  Circle: 'circle',
  Square: 'square',
  Checkmark: 'checkmark',
} as const;

interface CheckboxProps {
  variant?: ValuesOf<typeof CheckboxVariant>;
  value: boolean;
  onChange: (x: boolean) => void;
}
export const Checkbox = ({
  variant = CheckboxVariant.Circle,
  value,
  onChange,
}: CheckboxProps) => {
  return (
    <Container center onClick={() => onChange(!value)}>
      <Icon
        src={Icons[variant][value ? 'on' : 'off']}
        size={variant === 'square' ? 12 : 23}
      />
    </Container>
  );
};

const Icons = {
  [CheckboxVariant.Circle]: {
    on: require('asset/common/checkbox_on.svg').default,
    off: require('asset/common/checkbox_off.png').default,
  },
  [CheckboxVariant.Square]: {
    on: require('asset/common/checkbox_square_on.svg').default,
    off: require('asset/common/checkbox_square_off.svg').default,
  },
  [CheckboxVariant.Checkmark]: {
    on: require('asset/common/terms_on.svg').default,
    off: require('asset/common/terms_off.svg').default,
  },
};

const Container = styled(HorizontalLayout)<any>`
  cursor: pointer;
`;
const Icon = styled.img<any>`
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
`;
