import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { useStores } from 'state';
import { PickupMethod } from 'model';
import { Title, Subtitle } from './style';
import { RequestStep8 } from './RequestStep8';

export const RequestStep7 = observer(({
  Bottom,
  onNext,
}) => {
  const { customerStore } = useStores();
  const [feeConfirmShow, setFeeConfirmShown] = useState(false);

  const onSelect = (x: PickupMethod) => {
    if (x === PickupMethod.Car) {
      showConfirm(
        '선생님 차량 이용시, 매칭이\n어려울 수 있으니 다른 픽업 방법도\n중복으로 선택해주세요.',
        '선생님 차량에 설치할 카시트는\n부모님이 따로 준비해주셔야 합니다.',
      );
    } else if (!feeConfirmShow && (x === PickupMethod.Bus || x === PickupMethod.Taxi)) {
      showConfirm('교통비는 선생님과 조율하여\n개별적으로 지급해드려야 하니\n참고해주세요!');
      setFeeConfirmShown(true);
    }
  };

  useEffect(() => {
    if (!customerStore.form.pickupMethods) return;

    customerStore.form.pickup = customerStore.form.pickupMethods.join(',');
  }, [customerStore.form.pickupMethods]);

  return (
    <>
      <Title>
        픽업 방법을 선택해 주세요.
      </Title>
      <Space height={60} />

      <MultiSelect
        twoColumns
        items={[
          { label: '도보이동', value: PickupMethod.Walk },
          { label: '대중교통', value: PickupMethod.Bus },
          { label: '택시', value: PickupMethod.Taxi },
          { label: '선생님 차량', value: PickupMethod.Car },
          { label: '스쿨버스', value: PickupMethod.SchoolBus },
          { label: '픽업 불필요', value: PickupMethod.None },
        ]}
        value={customerStore.form.pickupMethods}
        onSelect={onSelect}
        onChange={e => customerStore.form.pickupMethods = e}
      />
      <Space height={60} />
      <Bottom
        progress={1 / 11 * 7}
        disabled={customerStore.form.pickupMethods.length === 0}
        onClickNext={() => onNext(RequestStep8)}
      />
    </>
  );
});
