import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import { useStores } from 'state';
import { useIsMobile } from 'util/index';

interface BannerProps {
  index?: number;
};
export const Banner = ({
  index = 0,
}: BannerProps) => {
  const { bannerStore } = useStores();
  const [banners, setBanners] = useState<any[]>();
  const isMobile = useIsMobile();
  const banner = banners?.find(x => x.title.includes(`${index}`));

  useEffect(() => {
    (async () => {
      setBanners(await bannerStore.getBanners());
    })();
  }, []);

  if (!banners || banners.length === 0 || !banner)
    return <></>;

  return (
    <Container
      isMobile={isMobile}
    >
      <Slider
        autoplay
        infinite
      >
        <BannerImage
          isMobile={isMobile}
          src={`https://api.woorihero.com${banner.imgPath}`}
          onClick={() => banner.content && window.open(banner.content, '_blank')}
        />
        {/*banners.map(x => (
          <BannerImage
            key={x.imgPath}
            isMobile={isMobile}
            src={`https://api.woorihero.com${x.imgPath}`}
            onClick={() => x.content && window.open(x.content, '_blank')}
          />
        ))*/}
      </Slider>
    </Container>
  )
};

const Container = styled.div<any>`
  width: 100%;

  cursor: pointer;

  overflow: hidden;

  ${({ isMobile }) => isMobile ? `
    height: 70px;
  ` : `
    height: 150px;
  `}
`;
const BannerImage = styled.img<any>`
  width: 100%;

  object-fit: contain;

  ${({ isMobile }) => isMobile ? `
    height: 70px;
  ` : `
    height: 150px;
  `}
`;
