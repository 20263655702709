import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuItem as MuiMenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList } from '@material-ui/core';

import { Color } from 'constant';

interface MenuItemProps {
  label: string;
  children: React.ReactNode;
};
export const MenuItem = ({
  label,
  children,
}: MenuItemProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <ul style={{ height: '100%' }}>
      <MenuItemText
        bold
        active={Boolean(anchorEl)}
        onMouseEnter={(e: any) => setAnchorEl(e.currentTarget)}
        onMouseLeave={(e: any) => {
          if (e.relatedTarget !== e.target.parentElement.parentElement)
            return;
          setTimeout(() => {
            setAnchorEl(null);
          }, 100);
        }}
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
      >
        {label}
      </MenuItemText>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper
              onMouseLeave={(e: any) => {
                setTimeout(() => {
                  setAnchorEl(null);
                }, 100);
              }}
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <MenuList autoFocusItem={Boolean(anchorEl)}>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ul>
  );
};

export const MenuItemText = styled.li<any>`
  display: flex;
  height: 100%;

  font-size: 19px;

  cursor: pointer;

  align-items: center;
  padding-left: 21px;
  padding-right: 15px;

  &:hover {
    color: ${Color.Primary};
  }

  ${({ bold }) => bold ? `
    font-weight: bold;
  ` : `
    font-weight: normal;
  `}
  ${({ active }) => active ? `
    color: ${Color.Primary};
    border-bottom: 3px solid ${Color.Primary};
  ` : `
    color: #484848;
  `}
`;
