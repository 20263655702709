import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { uniq, groupBy } from 'lodash';

import { Tag } from 'atom/deco';
import { Push, Space, HorizontalLayout, VerticalLayout } from 'atom/layout';
import { Color } from 'constant';
import { useTopmost, calcPrice, getHours } from 'util/index';
import { useChildren } from 'state/hook';
import { useStores } from 'state';
import { Title } from './style';
import { RequestFaq } from './RequsetFaq';

const Weekdays = ['월', '화', '수', '목', '금', '토', '일'];

const joinWeekdays = (x: number[]) => {
  return x.map(x => Weekdays[x]).join(', ');
};

export const RequestConfirm = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [fetching, setFetching] = useState(false);
  const children = useChildren();

  const onSubmit = async () => {
    //if (fetching) return;
    onNext(RequestFaq);
  };

  useEffect(() => {
    (async () => {
      try {
        //setFetching(true);
        await customerStore.submitRequest(customerStore.form);
      } catch (e) {
        alert('신청중 에러가 발생했습니다.');
      }
    })();
  }, []);

  return (
    <>
      <Title>
        돌봄을 이렇게
        <br />
        신청해주셨어요!
      </Title>
      <Space height={33} />

      <InfoBox>
        <InfoCategory>아이 정보</InfoCategory>
        {children?.map(x => (
          <div>
            {x.name} / {x.gender === 'M' ? '남아' : '여아'} / {x.birthday}
            <br />
          </div>
        ))}
        <br />
        <InfoCategory>돌봄 종류</InfoCategory>- {customerStore.form.careKind}
        <br />- {customerStore.form.careTypes.join(', ')}
        <br />
        <br />
        <InfoCategory>돌봄 기간</InfoCategory>
        {customerStore.form.careKind === '정기' && (
          <div>
            매주{' '}
            {joinWeekdays(
              uniq(customerStore.form.schedules.map(x => x.weekday).flat()),
            )}
            {Object.keys(groupBy(customerStore.form.schedules, 'type')).map(
              x => (
                <div>
                  - {x}{' '}
                  {
                    groupBy(customerStore.form.schedules, 'type')[x][0]
                      .startTime
                  }{' '}
                  ~{' '}
                  {groupBy(customerStore.form.schedules, 'type')[x][0].endTime}
                </div>
              ),
            )}
          </div>
        )}
        {customerStore.form.careKind !== '정기' && (
          <div>
            {Object.keys(groupBy(customerStore.form.schedules, 'date')).map(
              x => (
                <>
                  {DateTime.fromFormat(x, 'yyyy-MM-dd').toFormat('MM월 dd일')}{' '}
                  <br />
                  {groupBy(customerStore.form.schedules, 'date')[x].map(y => (
                    <>
                      - {y.type} / {y.startTime} ~ {y.endTime}
                      <br />
                    </>
                  ))}
                </>
              ),
            )}
          </div>
        )}
        <br />
        <InfoCategory>돌봄 장소</InfoCategory>- {customerStore.form.address1}{' '}
        {customerStore.form.address2}
        <br />
      </InfoBox>
      <Space height={30} />

      <CareCard />
      <Space height={50} />

      <Bottom
        progress={null}
        nextLabel="확인"
        onClickPrev={null}
        onClickNext={onSubmit}
      />
    </>
  );
});

const CareCard = ({}) => {
  const { customerStore } = useStores();

  console.log(customerStore.form);

  const isRegular = customerStore.form.careKind === '정기';
  const totalTime =
    customerStore.form.schedules
      .map(
        x =>
          DateTime.fromFormat(x.endTime, 'hh:mm').diff(
            DateTime.fromFormat(x.startTime, 'hh:mm'),
            'hours',
          ).hours * (x.weekday?.length || 1),
      )
      .reduce((sum, v) => sum + v, 0) * (isRegular ? 4 : 1); // 정기는 4주 기준 가격
  const hasSecondChild = !!customerStore.form.sndChildId;
  console.log(customerStore.form);
  console.log(
    customerStore.form.schedules.map(
      x =>
        DateTime.fromFormat(x.startTime, 'hh:mm').diff(
          DateTime.fromFormat(x.endTime, 'hh:mm'),
          'hours',
        ).hours,
    ),
  );

  const _calcPrice = (type?: string) => {
    return calcPrice(
      customerStore.form.schedules,
      customerStore.form.careKind,
      customerStore.form.careForm,
      type,
    );
  };
  const price = _calcPrice();
  const pricePremium = _calcPrice('창의 놀이');
  const priceCleaning = _calcPrice('청소 서비스');
  const priceCooking = _calcPrice('가사 서비스');

  console.log(hasSecondChild);
  console.log(totalTime);

  return (
    <CardContainer>
      <HorizontalLayout>
        <CardTitle>예상 돌봄 비용</CardTitle>
        <Push />
        <Tag>
          {customerStore.form.careKind === '비정기' ? '비정기' : '4주 기준'}
        </Tag>
      </HorizontalLayout>
      <Space height={10} />
      <VerticalLayout style={{ gap: '13px' }}>
        <CardText style={{ color: Color.Primary }}>
          총 예상금액 {price.toLocaleString()}원
        </CardText>
        {customerStore.form.careKind === '정기' && (
          <>
            {[
              {
                label: '기본 놀이돌봄',
                value: price - pricePremium - priceCleaning,
              },
              { label: '창의 놀이', value: pricePremium },
              { label: '청소 서비스', value: priceCleaning },
              { label: '가사 서비스', value: priceCooking },
            ]
              .filter(x => x.value > 0)
              .map((x, idx) => (
                <HorizontalLayout key={idx} center>
                  <CardText>{x.label}</CardText>
                  <div style={{ color: '#999', fontSize: '12px' }}>
                    &nbsp;|&nbsp;
                  </div>
                  <CardText>
                    주{' '}
                    {
                      uniq(
                        customerStore.form.schedules.map(x => x.weekday).flat(),
                      ).length
                    }
                    회
                  </CardText>
                  <CardText>
                    &nbsp;/ 총{' '}
                    {getHours(
                      customerStore.form.schedules,
                      customerStore.form.careKind,
                      customerStore.form.careForm,
                      x.label === '기본 놀이돌봄' ? null : x.label,
                    )}
                    시간
                  </CardText>
                  <Space width={4} />
                  <div style={{ color: '#999', fontSize: '12px' }}>|</div>
                  <Space width={4} />
                  <CardText style={{ color: Color.Primary }}>
                    {x.value?.toLocaleString()}원
                  </CardText>
                </HorizontalLayout>
              ))}
          </>
        )}
        {customerStore.form.careKind === '비정기' && (
          <>
            {[
              {
                label: '기본 놀이돌봄',
                value: price - pricePremium - priceCleaning,
              },
              { label: '창의 놀이', value: pricePremium },
              { label: '청소 서비스', value: priceCleaning },
              { label: '가사 서비스', value: priceCooking },
            ]
              .filter(x => x.value > 0)
              .map((x, idx) => (
                <HorizontalLayout key={idx} center>
                  <CardText>{x.label}</CardText>
                  <CardText>
                    &nbsp;/ 총{' '}
                    {getHours(
                      customerStore.form.schedules,
                      customerStore.form.careKind,
                      customerStore.form.careForm,
                      x.label === '기본 놀이돌봄' ? null : x.label,
                    )}
                    시간
                  </CardText>
                  <div style={{ color: '#999', fontSize: '12px' }}>
                    &nbsp;|&nbsp;
                  </div>
                  <CardText style={{ color: Color.Primary }}>
                    {x.value?.toLocaleString()}원
                  </CardText>
                </HorizontalLayout>
              ))}
          </>
        )}
      </VerticalLayout>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  width: 100%;

  box-shadow: 3px 4px 12px 0 #dedede;
  border-radius: 10px;
  background-color: #fff;

  padding: 18px 18px;
`;
const CardTitle = styled.div`
  color: #707070;

  font-size: 20px;
  font-weight: bold;
`;
const CardText = styled.div`
  font-size: 15px;
  letter-spacing: -0.8px;
  color: #969696;
`;

const InfoBox = styled.div`
  width: 100%;

  color: #484848;

  border-radius: 10px;
  background-color: #f7f7f7;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.8px;

  padding: 19px 17px;
`;
const InfoCategory = styled.div`
  font-weight: bold;
`;
