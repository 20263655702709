import styled from 'styled-components';

import { Color } from 'constant';

interface TableProps {
  align?: 'center' | 'start';
  fontSize?: number;
};
export const Table = styled.table<TableProps>`
  width: calc(100% - 80px);
  max-width: 619px;

  border-collapse: collapse;
  border-style: hidden;

  td, th {
    border: 1px solid white;
  }
  thead {
    background: ${Color.Primary};
  }
  tbody tr:nth-child(2n + 1) {
    background: #f5f5f5;
  }
  tbody tr:nth-child(2n + 2) {
    background: #f9f9f9;
  }
  th {
    color: white;

    font-size: 12px;
    font-weight: 500;

    padding: 6px 10px;
  }
  td {
    color: #484848;

    font-size: 12px;
    font-weight: 400;

    line-height: 1.43;
    letter-spacing: -0.7px;

    padding: 6px 20px;

    ${({ fontSize = 12 }) => `
      font-size: ${fontSize}px;
    `}
    ${({ align = 'center' }) => align === 'center' ? `
      text-align: center;
    ` : `
      vertical-align: baseline;
    `}
  }
`;
