import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { RoundButton } from 'atom/button';
import { Space } from 'atom/layout';
import { Header } from 'component-mobile/header';
import { Footer } from 'component-mobile/footer';
import { FaqAccordian, FaqAccordianItem } from 'component/faq';
import { FaqContents, SitterIntroFaqContents } from 'content';
import { Banner } from 'component/banner';

enum FaqTabKind {
  Care = 'care',
  Sitter = 'sitter',
};

export const FaqPage = ({

}) => {
  const history = useHistory();

  return (
    <>
      <Header />
      <Container>
        <Space height={50} />
        <Title>
          자주묻는 질문
        </Title>

        {/*}
        <Tab
          items={[
            { label: '돌봄 서비스', value: FaqTabKind.Care },
            { label: '히어로 선생님', value: FaqTabKind.Sitter },
          ]}
          value={tabKind}
          onChange={e => setTabKind(e as FaqTabKind)}
        />
        */}
        <FaqContainer>
          {FaqContents.map((x, idx) => (
            <FaqAccordianItem
              key={idx}
              data={x}
            />
          ))}
        </FaqContainer>
        <Space height={65} />

        <RoundButton
          style={{ width: 'calc(100% - 80px)', marginLeft: '40px' }}
          onClick={() => history.push('/service/apply')}
        >
          돌봄서비스 신청
        </RoundButton>
        <Space height={43} />

        <Banner />
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  width: 100%;

  overflow-x: hidden;
  
  .MuiAccordion-root.Mui-expanded {
    background-color: rgba(246, 159, 48, 0.03) !important;
  }
  .MuiAccordionSummary-root {
    min-height: auto !important;
  }
  .MuiAccordionSummary-content {
    margin: 6px 0px !important;
    font-size: 15px !important;
  }
`;
const Title = styled.div`
  color: #484848;

  font-size: 23px;
  font-weight: bold;
  text-align: center;

  letter-spacing: -1.15px;
`;

const FaqContainer = styled.div`
  background: white;

  padding: 34px 18px;

  .MuiPaper-root.MuiAccordion-root {
    padding: 5px 5px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 32px !important;
  }
  .MuiAccordionSummary-root {
    font-size: 12px !important;
  }
  .MuiAccordionDetails-root {
    font-size: 12px !important;
  }
  .MuiAccordionSummary-expandIcon {
    zoom: 0.5;
  }
`;
