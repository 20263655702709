import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';

import { Checkbox, Labeled } from 'atom/input';
import { Space, HorizontalLayout } from 'atom/layout';
import { Black14 } from 'atom/text';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestPersonalityCheck1 } from './RequestPersonalityCheck1';

export const RequestChildInfo1 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();

  const onSubmit = () => {
    if (customerStore.childForm.name.length === 0) {
      showConfirm('아이 이름을 입력해주세요.');
      return;
    }
    if (customerStore.childForm.birthday.length !== 8) {
      showConfirm('생년월일 8자리를 입력해주세요.');
      return;
    }

    onNext(RequestPersonalityCheck1);
  };

  console.log(customerStore.childForm, customerStore.childForm.birthday);

  return (
    <>
      <Title>아이 기본 정보</Title>
      <Space height={50} />

      <Labeled label="이름">
        <TextField
          variant="outlined"
          placeholder="입력란"
          style={{ width: '100%' }}
          error={
            customerStore.childForm.name.length <= 2 ||
            customerStore.childForm.name.length > 16
          }
          value={customerStore.childForm.name}
          onChange={e => (customerStore.childForm.name = e.target.value)}
        />
      </Labeled>
      <Space height={20} />

      <Labeled label="생년월일 8자리">
        <TextField
          variant="outlined"
          placeholder="YYYYMMDD"
          type="number"
          inputProps={{ maxLength: 8 }}
          style={{ width: '100%' }}
          error={customerStore.childForm.birthday.length !== 8}
          value={customerStore.childForm.birthday}
          onChange={e => (customerStore.childForm.birthday = e.target.value)}
        />
      </Labeled>
      <Space height={20} />

      <Labeled label="성별">
        <HorizontalLayout>
          <Checkbox
            value={customerStore.childForm.gender === 'F'}
            onChange={() => (customerStore.childForm.gender = 'F')}
          />
          <Space width={8} />
          <Black14>여자아이</Black14>
          <Space width={40} />
          <Checkbox
            value={customerStore.childForm.gender === 'M'}
            onChange={() => (customerStore.childForm.gender = 'M')}
          />
          <Space width={8} />
          <Black14>남자아이</Black14>
        </HorizontalLayout>
      </Labeled>
      <Space height={75} />
      <Bottom
        disabled={
          customerStore.childForm.birthday.length === 0 ||
          customerStore.childForm.name.length === 0
        }
        progress={(1 / 5) * 1}
        onClickNext={onSubmit}
      />
    </>
  );
});
