import React, { useState, useEffect } from 'react';

import { ITrainingSchedule } from 'model';
import { useStores } from 'state';

export const useTrainingSchedules = () => {
  const { sitterStore } = useStores();
  const [data, setData] = useState<ITrainingSchedule[]>([]);

  useEffect(() => {
    (async () => {
      setData(await sitterStore.getTrainningSchedules());
    })();
  }, []);

  return data;
};
